import React, { useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card, Col, Form, Row } from "react-bootstrap";
// import DatePicker from "react-multi-date-picker";
import Multiselect from "react-select";
import moment from "moment";
import withLoader from "../../layout/Loader/WithLoader";
import "react-datepicker/dist/react-datepicker.css";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ParkingHistoryService from "../../services/parkingHistory.service";
import {
  CUSTOMDROPDOWNSTYLE,
  PAGELIMIT,
  ReplaceFunction,
} from "../../utils/constants";
import { useEffect } from "react";
import BusinessPartnerService from "../../services/businessPartner.service";
import AreaService from "../../services/area.service";
import { useTranslation } from "react-i18next";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import ExcelJS from "exceljs";
import "jspdf-autotable";
import Enums from "../../utils/enums";
import UserService from "../../services/user.service";
import { CircularProgress } from "@mui/material";

const BusinessPartnerParkHistory = () => {
  const { t } = useTranslation("common");
  const { RANGES } = Enums();
  const userService = UserService();
  const parkingHistoryService = ParkingHistoryService();
  const businessPartnerService = BusinessPartnerService();
  const areaService = AreaService();
  const tableRef = useRef();
  const [userName, setUserName] = useState("");
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [searchString, setSearchString] = useState("");
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [newCity, setNewCity] = useState([]);
  const [newArea, setNewArea] = useState([]);
  const [showStreet, setShowStreet] = useState(false);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [totalRows, setTotalRows] = useState("");
  const [adminDetails, setAdminDetails] = useState("");
  const [isDownloadLoading, setIsDownloadLoading] = useState("");

  const neosStorageString = localStorage.getItem("neos-storage");
  const neosStorage = JSON.parse(neosStorageString);

  const id = neosStorage && neosStorage.id;

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const getAdmin = async () => {
    const adminDetailResponse = await userService.getAdmin();
    setAdminDetails(adminDetailResponse.data.apiresponse.data);
  };

  const getCity = async () => {
    const response = await businessPartnerService.getBusinessPartner(id);
    setUserName(response.data.apiresponse.data.name);
    setShowStreet(response.data.apiresponse.data.showStreet);
    setNewCity(
      response.data.apiresponse.data.cityAssignement.map(
        (res) => res.cityDetail
      )
    );

    if (response.status === 200 || response.status === 201) {
      const mergedAreas = [];
      response.data.apiresponse.data.cityAssignement.map(async (res) => {
        const getArea = await areaService.getAreaByCity(res.cityDetail.id);
        const areas = getArea.data.apiresponse.data;
        mergedAreas.push(...areas);
      });
      setNewArea(mergedAreas);
    }
  };

  const getParkingHistory = async () => {
    const todayUTC = new Date();

    const startDate = new Date(
      Date.UTC(
        todayUTC.getUTCFullYear(),
        todayUTC.getUTCMonth(),
        todayUTC.getUTCDate()
      )
    );
    const startDateInMilliseconds = startDate.getTime();

    const endDate = new Date(
      Date.UTC(
        todayUTC.getUTCFullYear(),
        todayUTC.getUTCMonth(),
        todayUTC.getUTCDate(),
        23,
        59,
        59,
        999
      )
    );
    const endDateInMilliseconds = endDate.getTime();

    const response = await parkingHistoryService.getParkingHistory({
      areaId: selectedArea,
      cityId: selectedCity,
      fromDate: startDateInMilliseconds,
      pageLimit: dataPerPage,
      pageNumber: pageNumber,
      searchString: searchString,
      toDate: endDateInMilliseconds,
    });

    setData(response.data.apiresponse.data.bookings);
    setTotalRows(response.data.apiresponse.data.totalRecords);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  useEffect(() => {
    getAdmin();
  }, []);

  useEffect(() => {
    setInterval(() => {
      getParkingHistory();
    }, 5000);
  }, []);

  useEffect(() => {
    getParkingHistory();
  }, [
    pageNumber,
    startDate,
    endDate,
    selectedArea,
    selectedCity,
    searchString,
    dataPerPage,
  ]);

  // const uniqueParking = [...new Set(data.map((elt) => elt.parkingName))];
  const totalParking = data.length;
  const uniqueAreas = [...new Set(data.map((elt) => elt.areaName))];
  const totalArea = uniqueAreas.length;
  let totalCost = 0;
  for (let i = 0; i < data.length; i++) {
    totalCost += parseFloat(data[i].charge);
  }

  const allColumns = [
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: "username",
      cell: (row) => (
        <div title={`${row.username ? row.username : "--"}`}>
          {row.username ? row.username : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("customeruniqueid")}</strong>,
      selector: "uniqId",
      cell: (row) => (
        <div title={`${row.uniqId ? row.uniqId : "--"}`}>
          {row.uniqId ? row.uniqId : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("parkingname")}</strong>,
      selector: "parkingName",
      cell: (row) => (
        <div title={`${row.parkingName ? row.parkingName : "--"}`}>
          {row.parkingName ? row.parkingName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("streetaddress")}</strong>,
      selector: "streetName",
      cell: (row) => (
        <div title={`${row.streetName ? row.streetName : "--"}`}>
          {row.streetName ? row.streetName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("area")}</strong>,
      selector: "areaName",
      cell: (row) => (
        <div title={`${row.areaName ? row.areaName : "--"}`}>
          {row.areaName ? row.areaName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("city")}</strong>,
      selector: "cityName",
      cell: (row) => (
        <div title={`${row.cityName ? row.cityName : "--"}`}>
          {row.cityName ? row.cityName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("streetname")}</strong>,
      selector: "bookingStreetName",
      cell: (row) => (
        <div title={`${row.bookingStreetName ? row.bookingStreetName : "--"}`}>
          {row.bookingStreetName ? row.bookingStreetName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("starttime")}</strong>,
      selector: "startDateTime",
      cell: (row) => (
        <div
          title={`${moment(row.startDateTime).format("DD/MM/YYYY HH:mm:ss")}`}
        >
          {moment(row.startDateTime).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("endtime")}</strong>,
      selector: "endDateTime",
      cell: (row) => (
        <div title={`${moment(row.endDateTime).format("DD/MM/YYYY HH:mm:ss")}`}>
          {moment(row.endDateTime).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("numberplate")}</strong>,
      selector: "numberPlate",
      cell: (row) => (
        <div title={`${row.numberPlate ? row.numberPlate : "--"}`}>
          {row.numberPlate ? row.numberPlate : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalcost")}</strong>,
      selector: "charge",
      cell: (row) => (
        <div
          title={`${
            row.charge ? `€${ReplaceFunction(row.charge?.toFixed(2))}` : "--"
          }`}
        >
          {row.charge ? `€${ReplaceFunction(row.charge?.toFixed(2))}` : "--"}
        </div>
      ),
      sortable: true,
    },
    // {
    //   name: <strong className="tx-uppercase">{t("servicefeepaid")}</strong>,
    //   selector: "serviceFee",
    //   cell: (row) => (
    //     <div
    //       title={`${
    //         row.serviceFee
    //           ? `€${ReplaceFunction(row.serviceFee?.toFixed(2))}`
    //           : "--"
    //       }`}
    //     >
    //       {row.serviceFee
    //         ? `€${ReplaceFunction(row.serviceFee?.toFixed(2))}`
    //         : "--"}
    //     </div>
    //   ),
    //   sortable: true,
    // },
  ];

  const columns = showStreet
    ? allColumns
    : allColumns.filter(
        (column) => column.name.props.children !== "STREET NAME"
      );

  const area = [
    {
      value: 0,
      label: t("ALL"),
    },
    ...newArea.map((area) => ({
      label: area.name,
      value: area.id,
    })),
  ];

  const city = [
    {
      value: 0,
      label: t("ALL"),
    },
    ...newCity.map((city) => ({
      label: city.name,
      value: city.id,
    })),
  ];

  const handlesearch = (e) => {
    setSearchString(e.target.value);
  };

  const exportPDF = async () => {
    setIsDownloadLoading("pdf");
    const todayUTC = new Date();

    const startDate = new Date(
      Date.UTC(
        todayUTC.getUTCFullYear(),
        todayUTC.getUTCMonth(),
        todayUTC.getUTCDate()
      )
    );
    const startDateInMilliseconds = startDate.getTime();

    const endDate = new Date(
      Date.UTC(
        todayUTC.getUTCFullYear(),
        todayUTC.getUTCMonth(),
        todayUTC.getUTCDate(),
        23,
        59,
        59,
        999
      )
    );
    const endDateInMilliseconds = endDate.getTime();

    const response = await parkingHistoryService.getParkingHistory({
      areaId: selectedArea,
      cityId: selectedCity,
      fromDate: startDateInMilliseconds,
      searchString: searchString,
      toDate: endDateInMilliseconds,
    });

    const data = response.data.apiresponse.data.bookings;

    const totalParking = data.length;
    const uniqueAreas = [...new Set(data.map((elt) => elt.areaName))];
    const totalArea = uniqueAreas.length;
    let totalCost = 0;
    for (let i = 0; i < data.length; i++) {
      totalCost += parseFloat(data[i].charge);
    }

    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3, or A4
    const orientation = "landscape"; // portrait or landscape

    const pageWidth = 1190.7; // A4 page width in points
    const titleFontSize = 15;

    const doc = new jsPDF(orientation, unit, size);

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 950, 10, 170, 70);

    const title = "STORICO PARCHEGGI CREDITO REAMININD";
    const titleWidth = doc.getStringUnitWidth(title) * titleFontSize; // Calculate title width in points

    // Calculate the x-coordinate to center the title on the page
    const titleX = (pageWidth - titleWidth) / 2;
    doc.setFontSize(titleFontSize);
    doc.text(title, 40, 50);

    const footer = `NEOS Tech S.r.l. - ${adminDetails.address} - ${adminDetails.cap} (${adminDetails.province}) Tel. ${adminDetails.phoneNo} - Fax ${adminDetails.fax}`;
    doc.setFontSize(10);
    doc.text(footer, 40, 800);

    const headers = [
      [
        "CODICE UNICO",
        "CITTÀ",
        "LA ZONA",
        "DATA D'INIZIO",
        "DATA DI FINE",
        "COSTO",
        "QUOTA DI SERVIZIO PAGATA",
        "CREDITO REAMININD",
        "CREDITO PRECEDENTE",
        "TARGA",
      ],
    ];

    const tableData = data.map((elt) => [
      elt.uniqId,
      elt.cityName,
      elt.areaName,
      moment(elt.startDateTime).format("DD/MM/YYYY HH:mm:ss"),
      moment(elt.endDateTime).format("DD/MM/YYYY HH:mm:ss"),
      `€${elt.charge ? ReplaceFunction(elt.charge?.toFixed(2)) : elt.charge}`,
      `€${
        elt.serviceFee
          ? ReplaceFunction(elt.serviceFee?.toFixed(2))
          : elt.serviceFee
      }`,
      `€${
        elt.afterCredit
          ? ReplaceFunction(elt.afterCredit?.toFixed(2))
          : elt.afterCredit
      }`,
      `€${
        elt.beforeCredit
          ? ReplaceFunction(elt.beforeCredit?.toFixed(2))
          : elt.beforeCredit
      }`,
      elt.numberPlate,
    ]);

    tableData.push([
      {
        content: "Parcheggio totale:",
        colSpan: 2,
        styles: { halign: "right", fontStyle: "bold" },
      },
      totalParking,
      {
        content: "Costo totale:",
        colSpan: 2,
        styles: { halign: "right", fontStyle: "bold" },
      },
      `€${totalCost ? ReplaceFunction(totalCost?.toFixed(2)) : totalCost}`,
    ]);

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 100,
        head: headers,
        body: tableData,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }
    setIsDownloadLoading("");
    doc.save("Park_History.pdf");
  };

  const handleDownloadExcel = async () => {
    setIsDownloadLoading("excel");
    const todayUTC = new Date();

    const startDate = new Date(
      Date.UTC(
        todayUTC.getUTCFullYear(),
        todayUTC.getUTCMonth(),
        todayUTC.getUTCDate()
      )
    );
    const startDateInMilliseconds = startDate.getTime();

    const endDate = new Date(
      Date.UTC(
        todayUTC.getUTCFullYear(),
        todayUTC.getUTCMonth(),
        todayUTC.getUTCDate(),
        23,
        59,
        59,
        999
      )
    );
    const endDateInMilliseconds = endDate.getTime();

    const response = await parkingHistoryService.getParkingHistory({
      areaId: selectedArea,
      cityId: selectedCity,
      fromDate: startDateInMilliseconds,
      searchString: searchString,
      toDate: endDateInMilliseconds,
    });

    const data = response.data.apiresponse.data.bookings;

    const totalParking = data.length;
    const uniqueAreas = [...new Set(data.map((elt) => elt.areaName))];
    const totalArea = uniqueAreas.length;
    let totalCost = 0;
    for (let i = 0; i < data.length; i++) {
      totalCost += parseFloat(data[i].charge);
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      alignment: { horizontal: "center" },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["STORICO PARCHEGGI CREDITO REAMININD"]);
    worksheet.mergeCells("A1:J1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    titleRow.getCell(1).font = commonCellStyle.font;
    worksheet.addRow([]);
    worksheet.mergeCells("A2:J2");

    worksheet.addRow([]);
    worksheet.mergeCells("A3:J3");

    worksheet.addRow([]);
    worksheet.mergeCells("A4:J4");

    // Add column headers
    const headers = [
      "CODICE UNICO",
      "CITTÀ",
      "LA ZONA",
      "DATA D'INIZIO",
      "DATA DI FINE",
      "COSTO",
      "QUOTA DI SERVIZIO PAGATA",
      "CREDITO REAMININD",
      "CREDITO PRECEDENTE",
      "TARGA",
    ];
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 18;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 22;
    worksheet.getColumn(9).width = 22;
    worksheet.getColumn(10).width = 15;
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.font = commonCellStyle.font;
      cell.alignment = commonCellStyle.alignment;
    });

    // Add data rows
    data.forEach((res) => {
      const table = worksheet.addRow([
        res.uniqId,
        res.cityName,
        res.areaName,
        moment(res.startDateTime).format("DD/MM/YYYY HH:mm:ss"),
        moment(res.endDateTime).format("DD/MM/YYYY HH:mm:ss"),
        `€${res.charge ? ReplaceFunction(res.charge?.toFixed(2)) : res.charge}`,
        `€${
          res.serviceFee
            ? ReplaceFunction(res.serviceFee?.toFixed(2))
            : res.serviceFee
        }`,
        `€${
          res.afterCredit
            ? ReplaceFunction(res.afterCredit?.toFixed(2))
            : res.afterCredit
        }`,
        `€${
          res.beforeCredit
            ? ReplaceFunction(res.beforeCredit?.toFixed(2))
            : res.beforeCredit
        }`,
        res.numberPlate,
      ]);
      table.eachCell((cell) => {
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
        cell.alignment = commonCellStyle.alignment;
      });
    });

    const totalRicaricheRow = worksheet.addRow([
      "",
      "Parcheggio totale:",
      `${totalParking}`,
      "",
      "Costo totale:",
      `€${totalCost ? ReplaceFunction(totalCost?.toFixed(2)) : totalCost}`,
    ]);
    totalRicaricheRow.getCell(2).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(3).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(5).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(6).alignment = commonCellStyle.alignment;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    setIsDownloadLoading("");
    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Park_History.xlsx`;
    link.click();
  };

  useEffect(() => {
    getCity();
  }, []);

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">{t("parkhistory")}</h2>
      <Card>
        <div className="p-3">
          <Row>
            <Col md={2}>
              <Form.Group className="form-group">
                <Multiselect
                  classNamePrefix="Select2"
                  options={area}
                  singleSelect
                  placeholder={t("area")}
                  displayValue="key"
                  name="area"
                  onChange={(selectedOption) =>
                    setSelectedArea(selectedOption.value)
                  }
                  styles={CUSTOMDROPDOWNSTYLE}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="form-group">
                <Multiselect
                  classNamePrefix="Select2"
                  options={city}
                  singleSelect
                  placeholder={t("city")}
                  displayValue="key"
                  name="city"
                  onChange={(selectedOption) =>
                    setSelectedCity(selectedOption.value)
                  }
                  styles={CUSTOMDROPDOWNSTYLE}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="form-group">
                {/* <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date())
                      .subtract(1, "months")
                      .format("MM/DD/YYYY"),
                    endDate: moment(new Date()).format("MM/DD/YYYY"),
                    ranges: RANGES,
                  }}
                  onApply={setDates}
                >
                  <input
                    type="text"
                    value={
                      moment(startDate).format("DD/MM/YYYY") +
                      " - " +
                      moment(endDate).format("DD/MM/YYYY")
                    }
                    className="form-control"
                  />
                </DateRangePicker> */}
                {/* <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date()).format("DD/MM/YYYY"),
                    endDate: moment(new Date()).format("DD/MM/YYYY"),
                    ranges: RANGES,
                    autoUpdateInput: false,
                    locale: {
                      format: "DD/MM/YYYY", // Specify the desired format
                    },
                  }}
                  onApply={setDates}
                  onCancel={cancelDate}
                  onHide={cancelDate}
                >
                  {startDate && endDate ? (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      value={
                        startDate && endDate
                          ? `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} - ${moment(endDate).format("DD/MM/YYYY")}`
                          : ""
                      }
                      defaultValue=""
                      onChange={(startDate, endDate) =>
                        handleDateChange(startDate, endDate)
                      }
                      className="form-control"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      defaultValue=""
                      className="form-control"
                    />
                  )}
                </DateRangePicker> */}
              </Form.Group>
            </Col>

            <Col md={6}>
              <div className="d-flex add-search">
                <div className="d-flex align-items-center md-form mt-auto ms-auto mb-2">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-md py-3"
                      value={searchString}
                      onChange={handlesearch}
                      placeholder={t("uniqueid") + " / " + t("numberplate")}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  {/* <DownloadTableExcel
                    filename={`${userName}_Park_History`}
                    sheet="users"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-sm btn-primary ms-2 p-2">
                      <i class="fa fa-download"></i> {t("excel")}
                    </button>
                  </DownloadTableExcel> */}
                  <div>
                    <button
                      className="btn btn-primary ms-2 btn-sm p-2 d-flex justify-content-center align-items-center"
                      style={{ width: "70px" }}
                      onClick={handleDownloadExcel}
                    >
                      {isDownloadLoading === "excel" ? (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      ) : (
                        <span>
                          <i class="fa fa-download"></i> {t("excel")}
                        </span>
                      )}
                    </button>
                    {/* <button
                      className="btn btn-primary ms-2 btn-sm p-2"
                      onClick={handleDownloadExcel}
                    >
                      <i class="fa fa-download"></i> {t("excel")}
                    </button> */}
                  </div>
                  <div>
                    <button
                      className="btn btn-primary ms-2 btn-sm p-2 d-flex justify-content-center align-items-center"
                      style={{ width: "60px" }}
                      onClick={exportPDF}
                    >
                      {isDownloadLoading === "pdf" ? (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      ) : (
                        <span>
                          <i class="fas fa-file-pdf"></i> PDF
                        </span>
                      )}
                    </button>
                    {/* <button
                      className="btn btn-sm btn-primary ms-2 p-2"
                      onClick={exportPDF}
                    >
                      <i class="fas fa-file-pdf"></i> PDF
                    </button> */}
                  </div>
                </div>
              </div>
            </Col>

            <table ref={tableRef} style={{ display: "none" }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>USERNAME</th>
                  <th>PARKING NAME</th>
                  <th>CITY</th>
                  <th>AREA</th>
                  <th>START DATE</th>
                  <th>END DATE</th>
                  <th>TOTAL COST</th>
                  <th>NUMBER PLATE</th>
                  <th>SERVICE FEE</th>
                  <th>REMAINING CREDIT</th>
                  <th>BEFORE CREDIT</th>
                </tr>
              </thead>
              <tbody>
                {data.map((data, index) => (
                  <tr key={index}>
                    <td>{data.uniqId}</td>
                    <td>{data.username}</td>
                    <td>{data.parkingName}</td>
                    <td>{data.cityName}</td>
                    <td>{data.areaName}</td>
                    <td>
                      {moment(data.startDateTime).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td>
                      {moment(data.endDateTime).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td>{data.charge}</td>
                    <td>{data.numberPlate}</td>
                    <td>{data.serviceFee}</td>
                    <td>{data.afterCredit}</td>
                    <td>{data.beforeCredit}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={12}></td>
                </tr>
                <tr>
                  <td colSpan={2} className="text-end fw-bold">
                    Total Parking:
                  </td>
                  <td className="text-start">{totalParking}</td>

                  <td colSpan={1} className="text-end fw-bold">
                    Total Area:
                  </td>
                  <td className="text-start">{totalArea}</td>

                  <td colSpan={2} className="text-end fw-bold">
                    Total Cost:
                  </td>
                  <td className="text-start">{totalCost}</td>
                </tr>
              </tbody>
            </table>
          </Row>
          <DataTable
            title="Business Partner Park History"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(BusinessPartnerParkHistory);
