import jsPDF from "jspdf";
import { ReplaceFunction } from "../../utils/constants";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";

const TotalParkingSpaces = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalAmount = 0;
  let totalEvent = 0;
  for (let i = 0; i < data?.length; i++) {
    totalAmount += parseFloat(data[i]?.sumOfCharge);
    totalEvent += parseFloat(data[i]?.totalEvent);
  }

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3, or A4
    const orientation = "landscape"; // portrait or landscape
    const titleFontSize = 20;
    const textColor = [0, 0, 0];

    const doc = new jsPDF(orientation, unit, size);

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        const footer1 = `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`;
        doc.setFontSize(13);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(footer1, 40, doc.internal.pageSize.height - 20);

        const footer2 = `Page ${i} of ${pageCount}`;
        doc.setFontSize(13);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(
          footer2,
          doc.internal.pageSize.width - 100,
          doc.internal.pageSize.height - 20
        );
      }
    };

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 780, 50, 350, 140);

    const title = "Parcheggi per Zona - APP";
    doc.setFontSize(titleFontSize);
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "bold");
    doc.text(title, 40, 140);

    const city = "Città:";
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(city, 40, 190);

    const cityName = selectedCity.label;
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(cityName, 120, 190);

    const period = "Data Inizio:";
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(period, 40, 240);

    const startdate = start;
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(startdate, 170, 240);

    const to = "Data Fine:";
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(to, 40, 270);

    const enddate = end;
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(enddate, 170, 270);

    const headers = [["Descrizione Zona", "N° Tot. Parcheggi", "Tot. Importo"]];

    // set data table columns names
    const newData = data.map((elt) => [
      {
        content: elt.areaname,
        styles: {
          halign: "left",
        },
      },
      {
        content: elt.totalEvent,
        styles: {
          halign: "center",
        },
      },
      {
        content: "€" + ReplaceFunction(elt.sumOfCharge?.toFixed(2)),
        styles: {
          halign: "right",
        },
      },
    ]);

    newData.push([
      {
        content: "",
        styles: {
          lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
          cellPadding: { top: 0, bottom: 0, left: 0, right: 0 },
        },
      },
      {
        content: "",
        styles: {
          lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
          cellPadding: { top: 0, bottom: 0, left: 0, right: 0 },
        },
      },
      {
        content: "",
        styles: {
          lineWidth: { top: 1, right: 0, bottom: 0, left: 0 },
          cellPadding: { top: 0, bottom: 0, left: 0, right: 0 },
        },
      },
    ]);

    newData.push([
      {
        content: "Totale periodo",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 0, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: totalEvent,
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: "€ " + ReplaceFunction(totalAmount?.toFixed(2)),
        colSpan: 1,
        styles: {
          halign: "right",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
    ]);

    const headStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      fontStyle: "bold",
      fontSize: 13,
      lineWidth: { top: 1, right: 1, bottom: 0, left: 1 },
      lineColor: [...textColor],
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 13,
      lineWidth: { top: 1, right: 1, bottom: 0, left: 1 },
      lineColor: [...textColor],
    };

    const alternateRowStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
    };

    const columnStyles = {
      0: { cellWidth: 700 },
      1: { cellWidth: 200 },
      2: { cellWidth: 200 },
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 300,
        head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    addFooter(doc);

    doc.save(
      `Totale_parcheggi_per_zona_${selectedCity.label}_${moment(
        startDate,
        "DD/MM/YYYY"
      ).format("DDMMYYYYHHmm")}.pdf`
    );
  };

  return exportPDF;
};

export default TotalParkingSpaces;
