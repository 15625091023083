import jsPDF from "jspdf";
import {
  ReplaceFunction,
  ReplaceFunctionWithoutDecimal,
} from "../../utils/constants";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";

const ReportByStreet = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  // Process the data to match the desired format
  const processData = (rawData) => {
    return rawData.map((area) => ({
      area: area.area,
      data: area.data.map((item) => ({
        streetName: item.streetName || "Via sconosciuta",
        totalEvent: item.totalEvent,
        totalTime: Math.round(item.totalTime / 60000), // Convert milliseconds to minutes
        averageTime: Math.round(item.totalTime / 60000 / item.totalEvent),
        sumOfCharge: item.sumOfCharge,
      })),
    }));
  };

  const newData = [];
  for (let i = 0; i < data.length; i++) {
    newData.push(data[i].areaName);
  }
  const areaData = [...new Set(newData)];

  const finalOldData = [
    ...areaData.map((item) => ({
      area: item,
      data: data.filter((dt) => dt.areaName === item),
    })),
  ];

  const finalData = processData(finalOldData);

  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalAmount = 0;
  let totalEvent = 0;
  finalData.forEach((area) => {
    area.data.forEach((item) => {
      totalAmount += parseFloat(item.sumOfCharge);
      totalEvent += parseFloat(item.totalEvent);
    });
  });

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape";
    const titleFontSize = 16;
    const textColor = [0, 0, 0];

    const doc = new jsPDF(orientation, unit, size);

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        const footer1 = `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`;
        doc.setFontSize(13);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(footer1, 40, doc.internal.pageSize.height - 20);

        const footer2 = `Page ${i} of ${pageCount}`;
        doc.setFontSize(13);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(
          footer2,
          doc.internal.pageSize.width - 100,
          doc.internal.pageSize.height - 20
        );
      }
    };

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 950, 60, 180, 80);

    const title = `Rendicontazione per: ${selectedCity.label}        Periodo: ${start} - ${end}`;
    doc.setFontSize(titleFontSize);
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "normal");
    doc.text(title, 40, 100);

    const headers = [
      [
        "Via",
        "N. di soste",
        "Tempo totale di parcheggio in minuti",
        "Tempo medio di parcheggio in minuti",
        "Importo",
      ],
    ];

    let yOffset = 160;

    finalData.forEach((area) => {
      const areaTitle = area.area;
      doc.setFontSize(titleFontSize);
      doc.setDrawColor(...textColor);
      doc.setTextColor(...textColor);
      doc.setFont("helvetica", "bold");
      doc.text(areaTitle, 40, yOffset);

      yOffset += 10;

      const areaData = area.data.map((elt) => [
        {
          content: elt.streetName,
          styles: { halign: "left" },
        },
        {
          content: ReplaceFunctionWithoutDecimal(elt.totalEvent),
          styles: { halign: "center" },
        },
        {
          content: ReplaceFunctionWithoutDecimal(elt.totalTime),
          styles: { halign: "center" },
        },
        {
          content: ReplaceFunctionWithoutDecimal(elt.averageTime),
          styles: { halign: "center" },
        },
        {
          content: "€" + ReplaceFunction(elt.sumOfCharge.toFixed(2)),
          styles: { halign: "right" },
        },
      ]);

      const areaTotalEvent = area.data.reduce(
        (sum, item) => sum + item.totalEvent,
        0
      );
      const areaTotalTime = area.data.reduce(
        (sum, item) => sum + item.totalTime,
        0
      );
      const areaTotalAverage = area.data.reduce(
        (sum, item) => sum + item.totalTime / item.totalEvent,
        0
      );

      const areaTotalAmount = area.data.reduce(
        (sum, item) => sum + item.sumOfCharge,
        0
      );

      areaData.push([
        {
          content: "Totale",
          colSpan: 1,
          styles: {
            halign: "left",
            fontStyle: "bold",
            lineWidth: { top: 1, right: 0, bottom: 1, left: 1 },
            lineColor: [...textColor],
            cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
          },
        },
        {
          content: ReplaceFunctionWithoutDecimal(areaTotalEvent),
          colSpan: 1,
          styles: {
            halign: "center",
            fontStyle: "bold",
            lineWidth: { top: 1, right: 1, bottom: 1, left: 1 },
            lineColor: [...textColor],
            cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
          },
        },
        {
          content: ReplaceFunctionWithoutDecimal(areaTotalTime),
          colSpan: 1,
          styles: {
            halign: "center",
            fontStyle: "bold",
            lineWidth: { top: 1, right: 1, bottom: 1, left: 1 },
            lineColor: [...textColor],
            cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
          },
        },
        {
          content: ReplaceFunctionWithoutDecimal(
            Math.floor(areaTotalTime / areaTotalEvent)
          ),
          // content: ReplaceFunctionWithoutDecimal(Math.floor(areaTotalAverage)),
          colSpan: 1,
          styles: {
            halign: "center",
            fontStyle: "bold",
            lineWidth: { top: 1, right: 1, bottom: 1, left: 1 },
            lineColor: [...textColor],
            cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
          },
        },
        {
          content: "€ " + ReplaceFunction(areaTotalAmount.toFixed(2)),
          colSpan: 1,
          styles: {
            halign: "right",
            fontStyle: "bold",
            lineWidth: { top: 1, right: 1, bottom: 1, left: 1 },
            lineColor: [...textColor],
            cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
          },
        },
      ]);

      const headStyles = {
        fillColor: "#ffffff",
        textColor: [...textColor],
        halign: "center",
        fontStyle: "bold",
        fontSize: 13,
        lineWidth: { top: 1, right: 1, bottom: 0, left: 1 },
        lineColor: [...textColor],
      };

      const bodyStyles = {
        fillColor: "#ffffff",
        halign: "center",
        textColor: [...textColor],
        cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
        fontSize: 13,
        lineWidth: { top: 1, right: 1, bottom: 0, left: 1 },
        lineColor: [...textColor],
      };

      const alternateRowStyles = {
        fillColor: "#ffffff",
        textColor: [...textColor],
      };

      const columnStyles = {
        0: { cellWidth: 300 },
        1: { cellWidth: 100 },
        2: { cellWidth: 200 },
        3: { cellWidth: 200 },
        4: { cellWidth: 100 },
      };

      if (typeof doc.autoTable === "function") {
        doc.autoTable({
          startY: yOffset,
          head: headers,
          body: areaData,
          headStyles: headStyles,
          bodyStyles: bodyStyles,
          alternateRowStyles: alternateRowStyles,
          columnStyles: columnStyles,
        });
      } else {
        console.error("jspdf-autotable plugin not loaded properly.");
      }

      yOffset = doc.lastAutoTable.finalY + 40;
    });

    yOffset += 20;

    addFooter(doc);

    doc.save(
      `Report_per_via_${selectedCity.label}_${moment(
        startDate,
        "DD/MM/YYYY"
      ).format("DDMMYYYYHHmm")}.pdf`
    );
  };

  return exportPDF;
};

export default ReportByStreet;
