import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsFilePdfFill } from "react-icons/bs";
import AreaService from "../../services/area.service";
import Select from "react-select";
import { CUSTOMDROPDOWNSTYLE, FORMIKERROR } from "../../utils/constants";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { IoCloseSharp } from "react-icons/io5";
import TotalParkingSpaces from "./1_TotalParkingSpaces";
import UserService from "../../services/user.service";
import CityService from "../../services/city.service";
import ReportService from "../../services/report.service";
import ParkingDetails from "./2_ParkingDetails";
import ReportByStreet from "./3_ReportByStreet";
import SummaryByCity from "./4_SummaryByCity";
import DealerRechargesByCity from "./5_DealerRechargesByCity";
import ReportServiceFeesCollected from "./6_ReportServiceFeesCollected";
import TotalParkingSpacesExcel from "./1_TotalParkingSpacesExcel";
import ParkingDetailsExcel from "./2_ParkingDetailsExcel";
import ReportByStreetExcel from "./3_ReportByStreetExcel";
import SummaryByCityExcel from "./4_SummaryByCityExcel";
import DealerRechargesByCityExcel from "./5_DealerRechargesByCityExcel";
import ReportServiceFeesCollectedExcel from "./6_ReportServiceFeesCollectedExcel";

const Reports = () => {
  const areaService = AreaService();
  const cityService = CityService();
  const reportService = ReportService();
  const userService = UserService();
  const { t } = useTranslation("common");
  const [area, setArea] = useState([]);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [adminDetails, setAdminDetails] = useState([]);
  const [newCity, setNewCity] = useState([]);
  const [isAreaDisabled, setIsAreaDisabled] = useState(true);

  const data = [
    {
      name: "1-Totale parcheggi per zona (città e periodo).pdf",
      component: TotalParkingSpaces,
      excel: TotalParkingSpacesExcel,
    },
    {
      name: "2-Dettaglio parcheggi per zona (città e periodo).pdf",
      component: ParkingDetails,
      excel: ParkingDetailsExcel,
    },
    {
      name: "3-Report per via.pdf",
      component: ReportByStreet,
      excel: ReportByStreetExcel,
    },
    {
      name: "4-Riepilogo per città.pdf",
      component: SummaryByCity,
      excel: SummaryByCityExcel,
    },
    {
      name: "5-Ricariche Dealer per città (città e periodo).pdf",
      component: DealerRechargesByCity,
      excel: DealerRechargesByCityExcel,
    },
    {
      name: "6-Report service fee incassate (città e periodo).pdf",
      component: ReportServiceFeesCollected,
      excel: ReportServiceFeesCollectedExcel,
    },
  ];

  const handlePDFClick = (name) => {
    setIsFilterShow(true);
    setSelectedReport(name);
  };

  const getArea = async (id) => {
    try {
      setIsAreaDisabled(true);
      const area = await areaService.getAreaByCity(id);
      setArea(area.data.apiresponse.data);
    } catch (error) {
      console.log("🚀 ~ getArea ~ error:", error);
    } finally {
      setIsAreaDisabled(false);
    }
  };

  const getAllCity = async () => {
    try {
      const response = await cityService.getAllPageCity();
      setNewCity(response.data.apiresponse.data);
    } catch (error) {
      console.log("🚀 ~ getAllCity ~ error:", error);
    }
  };

  const areaList = [
    { value: "all", label: "All" },
    ...area.map((item) => ({ value: item.id, label: item.name })),
  ];

  const cityList = [
    ...newCity.map((item) => ({ value: item.id, label: item.name })),
  ];

  const handelSubmit = async () => {
    setIsSubmitted(true);
    let response;
    const start = moment.utc(startDate, "DD/MM/YYYY").valueOf();
    const end = moment.utc(endDate, "DD/MM/YYYY").valueOf();

    if (startDate && endDate) {
      const selectedReportData = data.find(
        (item) => item.name === selectedReport
      );
      if (selectedReportData && selectedReportData.component) {
        if (
          selectedReport ===
            "1-Totale parcheggi per zona (città e periodo).pdf" &&
          selectedCity
        ) {
          response = await reportService.get1stReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedReport ===
            "2-Dettaglio parcheggi per zona (città e periodo).pdf" &&
          selectedCity
        ) {
          response = await reportService.get2ndReport(
            selectedArea
              ? selectedArea.label === "All"
                ? ""
                : selectedArea.label
              : "",
            selectedCity.label,
            end,
            start
          );
        } else if (selectedCity && selectedReport === "3-Report per via.pdf") {
          response = await reportService.get3rdReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedCity &&
          selectedReport === "4-Riepilogo per città.pdf"
        ) {
          response = await reportService.get4thReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedCity &&
          selectedReport ===
            "5-Ricariche Dealer per città (città e periodo).pdf"
        ) {
          response = await reportService.get5thReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedReport ===
          "6-Report service fee incassate (città e periodo).pdf"
        ) {
          response = await reportService.get6thReport(end, start);
        }
        const apiData = response.data.apiresponse.data;
        const exportPDF = selectedReportData.component({
          adminDetails,
          data: apiData,
          startDate,
          endDate,
          selectedArea,
          selectedCity,
        });
        exportPDF();
      }
    }
  };

  const handelSubmitExcel = async () => {
    setIsSubmitted(true);
    let response;
    const start = moment(startDate, "DD/MM/YYYY").valueOf();
    const end = moment(endDate, "DD/MM/YYYY").valueOf();

    if (startDate && endDate) {
      const selectedReportData = data.find(
        (item) => item.name === selectedReport
      );
      if (selectedReportData && selectedReportData.excel) {
        if (
          selectedReport ===
            "1-Totale parcheggi per zona (città e periodo).pdf" &&
          selectedCity
        ) {
          response = await reportService.get1stReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedReport ===
            "2-Dettaglio parcheggi per zona (città e periodo).pdf" &&
          selectedCity
        ) {
          response = await reportService.get2ndReport(
            selectedArea
              ? selectedArea.label === "All"
                ? ""
                : selectedArea.label
              : "",
            selectedCity.label,
            end,
            start
          );
        } else if (selectedCity && selectedReport === "3-Report per via.pdf") {
          response = await reportService.get3rdReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedCity &&
          selectedReport === "4-Riepilogo per città.pdf"
        ) {
          response = await reportService.get4thReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedCity &&
          selectedReport ===
            "5-Ricariche Dealer per città (città e periodo).pdf"
        ) {
          response = await reportService.get5thReport(
            selectedCity.label,
            end,
            start
          );
        } else if (
          selectedReport ===
          "6-Report service fee incassate (città e periodo).pdf"
        ) {
          response = await reportService.get6thReport(end, start);
        }
        const apiData = response.data.apiresponse.data;

        const exportExcel = selectedReportData.excel({
          adminDetails,
          data: apiData,
          startDate,
          endDate,
          selectedArea,
          selectedCity,
        });
        exportExcel();
      }
    }
  };

  useEffect(() => {
    getAllCity();
    getAdmin();
  }, []);

  const handleAreaChange = (selected) => {
    // setSelectedArea(selected);
    setSelectedArea(selected);
  };

  const handleCityChange = (selected) => {
    setSelectedCity(selected);
    getArea(selected.value);
    setSelectedArea({ value: "all", label: "All" });
  };

  const formatDateRange = (startDate) => {
    return `${startDate}`;
  };

  const handleDateChange = (type, date) => {
    const formattedDateRange = formatDateRange(date);

    const dateFormat = "DD/MM/YYYY";
    const dateMilliseconds = moment(formattedDateRange, dateFormat).format(
      "DD/MM/YYYY"
    );
    if (type === "start") {
      setStartDate(dateMilliseconds);
    } else {
      setEndDate(dateMilliseconds);
    }
  };

  const handelCancel = () => {
    setIsFilterShow(false);
    setIsSubmitted(false);
    setSelectedArea("");
    setSelectedCity("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const getAdmin = async () => {
    const adminDetailResponse = await userService.getAdmin();
    setAdminDetails(adminDetailResponse.data.apiresponse.data);
  };

  const formatOptionLabel = ({ label, value }, { context }) => {
    if (context === "value" && value !== "all") {
      return "All";
    }
    return label;
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">{t("reports")}</h2>

      <Row>
        <Col lg={6} className="mb-3">
          <Card>
            <div className="py-2 px-3">
              {data.map((item, index) => (
                <div
                  key={index}
                  className={`py-2 px-2 bg-white my-2 d-flex align-items-center pdf-main ${
                    selectedReport === item.name ? "active-pdf" : ""
                  }`}
                  style={{
                    border: "1px solid #E8E8F7",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePDFClick(item.name)}
                >
                  <BsFilePdfFill
                    style={{ color: "#E01E5A", fontSize: "38px" }}
                  />
                  <div className="ms-2">
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      {item.name}
                    </span>
                    <p
                      style={{ fontSize: "12px", color: "#8B8A8B" }}
                      className="m-0"
                    >
                      PDF
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Col>

        {isFilterShow && (
          <Col lg={6}>
            <Card className="p-3">
              <p className="font-weight-bold mb-3" style={{ fontSize: "16px" }}>
                {selectedReport}
              </p>

              {(selectedReport ===
                "1-Totale parcheggi per zona (città e periodo).pdf" ||
                selectedReport ===
                  "2-Dettaglio parcheggi per zona (città e periodo).pdf" ||
                selectedReport === "3-Report per via.pdf" ||
                selectedReport === "4-Riepilogo per città.pdf" ||
                selectedReport ===
                  "5-Ricariche Dealer per città (città e periodo).pdf") && (
                <div className="mb-3">
                  <Form.Label className="text-black">
                    {t("city")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    classNamePrefix="Select2"
                    options={cityList}
                    singleSelect
                    placeholder={t("city")}
                    displayValue="key"
                    name="cityId"
                    value={selectedCity}
                    onChange={handleCityChange}
                    styles={CUSTOMDROPDOWNSTYLE}
                  />
                  {(selectedReport ===
                    "1-Totale parcheggi per zona (città e periodo).pdf" ||
                    selectedReport ===
                      "2-Dettaglio parcheggi per zona (città e periodo).pdf" ||
                    selectedReport === "3-Report per via.pdf" ||
                    selectedReport === "4-Riepilogo per città.pdf" ||
                    selectedReport ===
                      "5-Ricariche Dealer per città (città e periodo).pdf") &&
                    isSubmitted &&
                    !selectedCity && (
                      <Form.Label style={FORMIKERROR}>
                        Please Select City
                      </Form.Label>
                    )}
                </div>
              )}

              {selectedReport ===
                "2-Dettaglio parcheggi per zona (città e periodo).pdf" && (
                <div className="mb-3">
                  <Form.Label className="text-black">
                    {t("area")}
                    <span className="text-danger"></span>
                  </Form.Label>
                  <Select
                    classNamePrefix="Select2"
                    options={areaList}
                    singleSelect
                    placeholder={t("area")}
                    displayValue="key"
                    name="areaId"
                    value={selectedArea}
                    onChange={handleAreaChange}
                    styles={CUSTOMDROPDOWNSTYLE}
                    isDisabled={isAreaDisabled}
                  />
                </div>
              )}

              <div className="mb-3">
                <Form.Label className="text-black">
                  {t("startdate")}
                  <span className="text-danger">*</span>
                </Form.Label>
                <DatePicker
                  calendarPosition="bottom-left"
                  name="holidayList.date"
                  value={startDate}
                  onChange={(date) => handleDateChange("start", date)}
                  format={"DD/MM/YYYY"}
                  style={{
                    width: "inherit",
                    height: "38px",
                    borderColor: "#D3D3DE",
                  }}
                  placeholder={t("startdate")}
                />
              </div>

              <div className="mb-3">
                <Form.Label className="text-black">
                  {t("enddate")}
                  <span className="text-danger">*</span>
                </Form.Label>
                <DatePicker
                  calendarPosition="bottom-left"
                  name="holidayList.date"
                  value={endDate}
                  onChange={(date) => handleDateChange("end", date)}
                  format={"DD/MM/YYYY"}
                  style={{
                    width: "inherit",
                    height: "38px",
                    borderColor: "#D3D3DE",
                  }}
                  placeholder={t("enddate")}
                />
              </div>

              <div className="d-flex justify-content-center gap-3">
                <button
                  className="btn btn-primary"
                  onClick={handelSubmit}
                  type="button"
                >
                  <span>
                    <i class="fas fa-file-pdf"></i> PDF
                  </span>
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handelSubmitExcel}
                  type="button"
                >
                  <span>
                    <i class="fa fa-download"></i> {t("excel")}
                  </span>
                </button>

                <button className="btn btn-danger" onClick={handelCancel}>
                  <IoCloseSharp style={{ fontSize: "20px" }} />{" "}
                  <span>{t("cancel")}</span>
                </button>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Reports;
