import jsPDF from "jspdf";
import { ReplaceFunction } from "../../utils/constants";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";

const ParkingDetails = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  // Group data by area
  const groupedData = data.reduce((acc, item) => {
    if (!acc[item.areaname]) {
      acc[item.areaname] = [];
    }
    acc[item.areaname].push(item);
    return acc;
  }, {});

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const titleFontSize = 18;
    const textColor = [0, 0, 0];
    const headerHeight = 160;

    const doc = new jsPDF(orientation, unit, size);

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const footer1 = `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`;
        doc.setFontSize(10);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(footer1, 40, doc.internal.pageSize.height - 20);

        const footer2 = `Page ${i} of ${pageCount}`;
        doc.setFontSize(10);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(
          footer2,
          doc.internal.pageSize.width - 100,
          doc.internal.pageSize.height - 20
        );
      }
    };

    // Function to add header content
    const addPageHeader = (doc, areaName, isFirstPage) => {
      if (isFirstPage) {
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, doc.internal.pageSize.width, headerHeight, "F");

        // Add logo and title
        const imgData = NeosLogo;
        doc.addImage(imgData, "JPEG", 370, 20, 160, 60);

        const title = "Dettaglio Parcheggi Per Zona - APP";
        doc.setFontSize(titleFontSize);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(title, 40, 35);

        // Add period
        const period = `Dal    ${start}    al    ${end}`;
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(period, 40, 70);

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Città:", 40, 100);
        doc.text(selectedCity.label, 120, 100);

        // Add area
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Area:", 40, 120);
        doc.text(selectedCity.label, 120, 120);

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Zona:", 40, 140);
        doc.text(areaName, 120, 140);
      } else {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Città:", 40, 40);
        doc.text(selectedCity.label, 120, 40);

        // Add area
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Area:", 40, 60);
        doc.text(selectedCity.label, 120, 60);

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Zona:", 40, 80);
        doc.text(areaName, 120, 80);
      }
    };

    const addNewPageHeader = (doc, areaName) => {
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Città:", 40, 40);
      doc.text(selectedCity.label, 120, 40);

      // Add area
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Area:", 40, 60);
      doc.text(selectedCity.label, 120, 60);

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Zona:", 40, 80);
      doc.text(areaName, 120, 80);
    };

    // Define table styles
    const headers = [
      ["Targa", "Data inizio parcheggio", "Data fine parcheggio", "Importo"],
    ];

    const headStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      fontStyle: "bold",
      fontSize: 12,
      lineColor: [...textColor],
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 12,
      lineColor: [...textColor],
    };

    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 180 },
      2: { cellWidth: 180 },
      3: { cellWidth: 80 },
    };

    // Generate tables for each area
    Object.entries(groupedData)
      .sort(([areaNameA], [areaNameB]) => {
        return areaNameA.localeCompare(areaNameB);
      })
      .forEach(([areaName, areaData], index) => {
        // Create new page for each area except the first one
        const isFirstPage = index === 0;

        if (index > 0) {
          doc.addPage();
        }

        // Calculate total for this area
        let areaTotal = 0;
        areaData.forEach((item) => {
          areaTotal += parseFloat(item.charge);
        });

        const tableData = areaData.map((item) => [
          {
            content: item.number,
            styles: { halign: "center" },
          },
          {
            content: moment(Number(item.startDateTime)).format(
              "DD/MM/YYYY HH:mm"
            ),
            styles: { halign: "center" },
          },
          {
            content: moment(Number(item.endDateTime)).format(
              "DD/MM/YYYY HH:mm"
            ),
            styles: { halign: "center" },
          },
          {
            content: "€" + ReplaceFunction(item.charge.toFixed(2)),
            styles: { halign: "right" },
          },
        ]);

        // Add total row for this area
        tableData.push([
          {
            content: `TOTALE PARCHEGGI       ${areaData.length}`,
            colSpan: 2,
            styles: {
              halign: "left",
              fontStyle: "bold",
              lineColor: [...textColor],
              cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
            },
          },
          {
            content: "TOTALE IMPORTO",
            colSpan: 1,
            styles: {
              halign: "center",
              fontStyle: "bold",
              lineColor: [...textColor],
              cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
            },
          },
          {
            content: "€ " + ReplaceFunction(areaTotal.toFixed(2)),
            colSpan: 1,
            styles: {
              halign: "right",
              fontStyle: "bold",
              lineColor: [...textColor],
              cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
            },
          },
        ]);

        if (typeof doc.autoTable === "function") {
          // First add the header
          addPageHeader(doc, areaName, isFirstPage);

          // Then add the table with fixed margins
          doc.autoTable({
            startY: isFirstPage ? headerHeight : headerHeight - 60,
            margin: { top: headerHeight - 60 },
            head: headers,
            body: tableData,
            headStyles: headStyles,
            bodyStyles: bodyStyles,
            columnStyles: columnStyles,
            didDrawPage: function (data) {
              // Add header when a new page is created
              if (data.pageCount > 1 && data.pageNumber > 1) {
                addNewPageHeader(doc, areaName);
              }
            },
            willDrawCell: function (data) {
              // Ensure we're not drawing over the header
              if (data.row.y < headerHeight) {
                data.row.y = headerHeight;
              }
            },
          });
        }
      });

    addFooter(doc);

    doc.save(
      `Dettaglio_parcheggi_per_zona_${selectedCity.label}_${
        selectedArea?.label || "all"
      }_${moment(startDate, "DD/MM/YYYY").format("DDMMYYYYHHmm")}.pdf`
    );
  };

  return exportPDF;
};

export default ParkingDetails;
