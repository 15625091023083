import React from "react";
import ExcelJS from "exceljs";
import moment from "moment";
import { ReplaceFunction } from "../../utils/constants";

const ReportServiceFeesCollectedExcel = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalServiceFee = 0;
  let totalEvent = 0;
  data.forEach((item) => {
    totalServiceFee += parseFloat(item.totalServiceFee);
    totalEvent += parseFloat(item.totalEvent);
  });

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report service fee");

    // Styles
    const titleStyle = {
      font: { size: 14, bold: true },
      alignment: { horizontal: "center", vertical: "middle" },
    };
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "middle" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
    };

    // Add title
    const titleCell = worksheet.addRow(["Report service fee"]);
    worksheet.mergeCells("A1:C1");
    titleCell.font = { size: 14, bold: true };
    titleCell.height = 20;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };

    worksheet.addRow([]);

    // Add period
    const periodCell = worksheet.getCell("A3");
    periodCell.value = `Periodo: ${start} - ${end}`;
    periodCell.font = { size: 12, bold: true };
    periodCell.alignment = { horizontal: "left" };
    worksheet.mergeCells("A3:C3");

    worksheet.addRow([]);

    // Add headers
    const headers = ["Città", "N. di soste", "Importo"];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Add data
    data.forEach((item) => {
      const row = worksheet.addRow([
        item.cityName,
        item.totalEvent,
        ReplaceFunction(item.totalServiceFee.toFixed(2)) + " €",
      ]);

      row.getCell(1).alignment = { horizontal: "left" };
      row.getCell(2).alignment = { horizontal: "right" };
      row.getCell(3).alignment = { horizontal: "right" };
      row.getCell(3).numFmt = '"€"#,##0.00';
    });

    // Add totals
    const totalRow = worksheet.addRow([
      "Totale",
      totalEvent,
      ReplaceFunction(totalServiceFee.toFixed(2)) + " €",
    ]);
    totalRow.eachCell((cell) => {
      cell.style = {
        font: { bold: true },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
        },
      };
    });
    totalRow.getCell(1).alignment = { horizontal: "left" };
    totalRow.getCell(2).alignment = { horizontal: "right" };
    totalRow.getCell(3).alignment = { horizontal: "right" };
    totalRow.getCell(3).numFmt = '"€"#,##0.00';

    // Set column widths
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 20;

    worksheet.addRow([]);

    // Add footer
    const footerRow = worksheet.addRow([
      `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`,
    ]);
    worksheet.mergeCells(`A${worksheet.rowCount}:C${worksheet.rowCount}`);
    footerRow.getCell(1).font = { size: 10 };

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Report_service_fee_incassate_${moment(
      startDate,
      "DD/MM/YYYY"
    ).format("DDMMYYYYHHmm")}.xlsx`;
    link.click();
  };

  return downloadExcel;
};

export default ReportServiceFeesCollectedExcel;
