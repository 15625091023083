import React from "react";
import ExcelJS from "exceljs";
import moment from "moment";
import { ReplaceFunction } from "../../utils/constants";

const ParkingDetailsExcel = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Dettaglio Parcheggi");

    // Add title
    const titleRow = worksheet.addRow(["Dettaglio Parcheggi Per Zona - APP"]);
    titleRow.font = { bold: true };
    worksheet.mergeCells("A1:D1");
    titleRow.alignment = { horizontal: "center" };

    // Add period
    worksheet.addRow([]);
    const periodRow = worksheet.addRow([`Dal ${start} al ${end}`]);
    periodRow.font = { bold: true };
    worksheet.mergeCells("A3:D3");

    // Add city
    worksheet.addRow([]);
    worksheet.addRow([`Città:  ${selectedCity.label}`]);
    worksheet.mergeCells("A5:D5");
    worksheet.addRow([`Area:  ${selectedCity.label}`]);
    worksheet.mergeCells("A6:D6");

    // Sort data by zone for grouped headings
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.areaname]) acc[item.areaname] = [];
      acc[item.areaname].push(item);
      return acc;
    }, {});

    // Add headers for each zone
    Object.entries(groupedData)
      .sort(([areaNameA], [areaNameB]) => {
        return areaNameA.localeCompare(areaNameB);
      })
      .forEach(([zone, zoneData]) => {
        worksheet.addRow([]);
        const zonaRow = worksheet.addRow([`Zona:  ${zone}`]);
        zonaRow.font = { bold: true };
        // worksheet.addRow([`Zona:  ${zone}`]); // Dynamic zone header
        worksheet.mergeCells(
          `A${worksheet.lastRow.number}:D${worksheet.lastRow.number}`
        );

        // Add data headers
        const headers = [
          "Targa",
          "Data inizio parcheggio",
          "Data fine parcheggio",
          "Importo",
        ];
        const headerRow = worksheet.addRow(headers);
        headerRow.font = { bold: true };
        headerRow.alignment = { horizontal: "center" };

        // Add data rows for the zone
        let zoneTotal = 0;
        zoneData.forEach((item) => {
          zoneTotal += parseFloat(item.charge);
          const row = worksheet.addRow([
            item.number,
            moment(Number(item.startDateTime)).format("DD/MM/YYYY HH:mm"),
            moment(Number(item.endDateTime)).format("DD/MM/YYYY HH:mm"),
            "€" + ReplaceFunction(item.charge.toFixed(2)),
          ]);
          row.getCell(4).alignment = { horizontal: "right" };
        });

        // Add totals row for the zone
        const totalRow = worksheet.addRow([
          `TOTALE PARCHEGGI:     ${zoneData.length}`,
          "",
          "TOTALE IMPORTO",
          "€ " + ReplaceFunction(zoneTotal.toFixed(2)),
        ]);
        worksheet.addRow([]);
        totalRow.font = { bold: true };
        worksheet.mergeCells(`A${totalRow.number}:B${totalRow.number}`);
        totalRow.getCell(4).alignment = { horizontal: "right" };
      });

    // Add footer with admin details
    worksheet.addRow([]);
    const footerRow1 = worksheet.addRow([
      `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`,
    ]);
    footerRow1.font = { size: 10 };
    worksheet.mergeCells(`A${footerRow1.number}:D${footerRow1.number}`);

    // Set column widths
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 15;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Dettaglio_parcheggi_per_zona_${selectedCity.label}_${
      selectedArea?.label || "all"
    }_${moment(startDate, "DD/MM/YYYY").format("DDMMYYYYHHmm")}.xlsx`;
    link.click();
  };

  return downloadExcel;
};

export default ParkingDetailsExcel;
