import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Container,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Auth from "../../../store/slice/auth";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import {
  ANCHORORIGIN,
  ERROR,
  FORMIKERROR,
  SUCCESS,
} from "../../utils/constants";
import ForgotPassword from "./ForgotPassword";
import Enums from "../../utils/enums";
import { useTranslation } from "react-i18next";
import IT from "../../../assets/img/it.svg";
import US from "../../../assets/img/us.png";

const Login = () => {
  const auth = Auth();
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(localStorage.getItem("language"));
  const { ROLE, DEALER_TYPE } = Enums();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForgotPasssword, setShowForgotPasssword] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLanguageChange = (e) => {
    window.localStorage.setItem("language", e);
    i18n.changeLanguage(e);
    setValue(e);
  };

  const handleFormSubmit = async (values, action) => {
    const { email, password } = values;
    const neosStorageString = localStorage.getItem("neos-storage");
    const neosStorage = JSON.parse(neosStorageString);

    const role = neosStorage && neosStorage.role;
    const type = neosStorage && neosStorage.dealerType;

    try {
      setLoading(true);
      const response = await dispatch(auth.login({ email, password })).unwrap();
      dispatch(auth.setLoggedIn(true));

      if (
        response.data.apiresponse.data.role !== "CHILD_USER" &&
        response.data.apiresponse.data.role !== "USER" &&
        response.data.apiresponse.data.role !== "BUSINESS_USER"
      ) {
        enqueueSnackbar(t("SUCCESSFULLY_LOGIN"), {
          variant: SUCCESS,
          anchorOrigin: ANCHORORIGIN,
        });
        window.location.reload();
      }
      if (response.data.apiresponse.data.role === "USER") {
        enqueueSnackbar("User cannot login", {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
        return;
      }
      if (response.data.apiresponse.data.role === "BUSINESS_USER") {
        enqueueSnackbar("Business User cannot login", {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
        return;
      }
      if (response.data.apiresponse.data.role === "CHILD_USER") {
        enqueueSnackbar("Child User cannot login", {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
        return;
      }
      if (ROLE.find((item) => item.value === role)) {
        navigate("/dashboard/");
        window.location.reload();
      }
      if (
        ROLE.find((item) => item.value === role) &&
        DEALER_TYPE.find((item) => item.value === type)
      ) {
        navigate("/freedealerdashboard/");
        window.location.reload();
      }
      if (
        ROLE.find((item) => item.value === role) &&
        DEALER_TYPE.find((item) => item.value === type)
      ) {
        navigate("/paiddealerdashboard/");
        window.location.reload();
      }
      if (ROLE.find((item) => item.value === role)) {
        navigate("/businesspartnerdashboard/");
        window.location.reload();
      }
      if (ROLE.find((item) => item.value === role)) {
        navigate("/childbusinesspartnerparkhistory/");
        window.location.reload();
      }
      if (ROLE.find((item) => item.value === role)) {
        enqueueSnackbar(t("CHILD_USER_LOGIN_ERROR"), {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
        navigate("/login");
      }
      setLoading(false);
    } catch (e) {
      formik.resetForm();
      enqueueSnackbar(e.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    } finally {
      setLoading(false);
      formik.resetForm();
    }
  };

  // Forgot Password Page Call and Login Page Remove
  const handleForgotPasssword = () => {
    setShowForgotPasssword(true);
    setShowLogin(false);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required!"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    if (localStorage.getItem("language") !== null) {
      const lang = localStorage.getItem("language");
      i18n.changeLanguage(lang);
      setValue(lang);
    } else {
      setValue("it");
      i18n.changeLanguage("it");
      localStorage.setItem("language", "it");
    }
  }, []);

  const handleEmail = (e) => {
    const regex = "^(?!.*[A-Z]).*$";

    const value = e.target.value;
    if (value.match(regex)) {
      formik.setFieldValue("email", value);
    }
  };

  return (
    <>
      {showForgotPasssword && <ForgotPassword />}
      {showLogin && (
        <Fragment>
          <div className="d-flex align-items-center ms-3 mt-2">
            <span className="text-muted tx-15">{t("selectlanguage")} : </span>
            <Dropdown
              id="nav-dropdown-dark-example"
              title={"en"}
              className="main-profile-menu"
              onSelect={(e) => handleLanguageChange(e)}
            >
              <Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="text-dark"
              >
                {value === "it" ? (
                  <span>
                    {" "}
                    <img src={IT} alt="" width={"20px"} /> Italian
                  </span>
                ) : (
                  <span>
                    <img src={US} alt="" width={"20px"} /> English
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-lang-login">
                <Dropdown.Item
                  eventKey={"en"}
                  // className="dropdown-item"
                  className="header-navheading d-flex align-items-center"
                  style={{ cursor: "pointer", height: "45px" }}
                >
                  <span className="align-items-center">
                    <img src={US} alt="" width={"20px"} /> English
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={"it"}
                  className="header-navheading d-flex align-items-center"
                  style={{ cursor: "pointer", height: "45px" }}
                >
                  <span className="align-items-center">
                    <img src={IT} alt="" width={"20px"} /> Italian
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className="page main-signin-wrapper"
            style={{ marginTop: "-48px" }}
          >
            <Row className="signpages text-center">
              <Col md={12}>
                <Card>
                  <Row className="row-sm">
                    <Col
                      lg={6}
                      xl={5}
                      className="d-flex justify-content-center d-lg-block text-center bg-primary details"
                    >
                      <div className="mt-5 pt-4 p-2 pos-absolute">
                        <img
                          src={
                            require("../../../assets/img/svgs/user.svg").default
                          }
                          className="ht-100 mb-0"
                          alt="user"
                        />
                        <h5 className="mt-4 text-white">
                          {t("createyouraccount")}
                        </h5>
                        <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                          {t("signuptocreatesentance")}
                        </span>
                      </div>
                    </Col>
                    <Col lg={6} xl={7} xs={12} sm={12} className="login_form ">
                      <Container fluid>
                        <Row className="row-sm">
                          <Card.Body className="mt-2 mb-2">
                            <form onSubmit={formik.handleSubmit}>
                              <Form.Group
                                className="text-start form-group"
                                controlId="formEmail"
                              >
                                <h5 className="text-start mb-2 d-flex justify-content-center">
                                  {t("logintoneos")}
                                </h5>
                                <p className="mb-4 text-muted tx-13 ms-0 text-center">
                                  {t("logintocreatesentance")}
                                </p>
                                <Form.Group
                                  className="text-start form-group"
                                  controlId="formEmail"
                                >
                                  <Form.Label> {t("email")}</Form.Label>
                                  <Form.Control
                                    className="form-control text-lowercase"
                                    placeholder={t("enteryouremail")}
                                    name="email"
                                    type="text"
                                    onChange={handleEmail}
                                    value={formik.values.email}
                                  />
                                  {formik.errors.email &&
                                  formik.touched.email ? (
                                    <Form.Label style={FORMIKERROR}>
                                      {formik.errors.email}
                                    </Form.Label>
                                  ) : null}
                                </Form.Group>
                                <Form.Group
                                  className="text-start form-group"
                                  controlId="formpassword"
                                >
                                  <Form.Label className="text-dark">
                                    Password
                                  </Form.Label>
                                  <div
                                    className="d-flex"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                    }}
                                  >
                                    <Form.Control
                                      className="form-control"
                                      placeholder={t("enteryourpassword")}
                                      name="password"
                                      type={showPassword ? "text" : "password"}
                                      onChange={formik.handleChange}
                                      value={formik.values.password}
                                    />
                                    <i
                                      style={{
                                        position: "absolute",
                                        top: "38%",
                                        right: "4%",
                                      }}
                                      onClick={handleTogglePassword}
                                      className={`fa ${
                                        showPassword ? "fa-eye-slash" : "fa-eye"
                                      } text-dark`}
                                    ></i>
                                    {/* </button> */}
                                  </div>
                                  {formik.errors.password &&
                                  formik.touched.password ? (
                                    <Form.Label style={FORMIKERROR}>
                                      {formik.errors.password}
                                    </Form.Label>
                                  ) : null}
                                  <div className="d-flex">
                                    <Link
                                      // to={"/forgotpasssword"}
                                      onClick={handleForgotPasssword}
                                      className="mt-1 text-dark ms-auto"
                                    >
                                      {t("forgotpassword")}?
                                    </Link>
                                  </div>
                                </Form.Group>
                                {loading ? (
                                  <Button className="btn ripple btn-main-primary btn-block mt-2">
                                    <CircularProgress
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        color: "#ffffff",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-block mt-4 me-3"
                                  >
                                    Login
                                  </button>
                                )}
                              </Form.Group>
                            </form>
                          </Card.Body>
                        </Row>
                        <ToastContainer />
                      </Container>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>

          {/* <!-- End Row --> */}
        </Fragment>
      )}
    </>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
