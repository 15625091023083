import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Page } from "react-pdf";
import {
  ANCHORORIGIN,
  CUSTOM_STYLES,
  PAGELIMIT,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import withLoader from "../../layout/Loader/WithLoader";
import PreviewPDF from "../Invoices/PreviewPDF";
import useDialogState from "../../hooks/useDialog";
import "react-data-table-component-extensions/dist/index.css";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import PaymentService from "../../services/payment.service";
import InvoiceService from "../../services/invoice.service";
import moment from "moment";
import ExportPDF from "../Invoices/ExportPDF";
import UserService from "../../services/user.service";

const FreeDealerInvoices = () => {
  const invoiceService = InvoiceService();
  const userService = UserService();
  const { t } = useTranslation("common");
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [openPDF, setOpenPDF] = useState(false);
  const [numPagesTerms, setNumPagesTerms] = useState(null);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [adminDetails, setAdminDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const getInvoices = async () => {
    const response = await invoiceService.getDealerInvoice(
      dataPerPage,
      pageNumber
    );

    setData(response.data.apiresponse.data.invoices);
    setTotalRows(response.data.apiresponse.data.totalRecords);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  useEffect(() => {
    getInvoices();
  }, [dataPerPage, pageNumber]);

  const viewPDF = async (id) => {
    setOpenPDF(true);
    const response = await invoiceService.invoiceDetails(id);
    setInvoiceDetail(response.data.apiresponse.data.invoiceDetail);
    setUserDetails(response.data.apiresponse.data.userDetail);

    const adminDetailResponse = await userService.getAdmin();
    setAdminDetails(adminDetailResponse.data.apiresponse.data);
    getInvoices();
  };

  function onDocumentTermsLoadSuccess({ numPages }) {
    setNumPagesTerms(numPages);
  }

  const PageWithNumber = ({ pageNumber }) => (
    <div style={{ position: "relative" }}>
      <Page pageNumber={pageNumber} width={900} />
      <div
        style={{
          textAlign: "center",
          background: "#FFFFFF",
          opacity: 0.8,
        }}
      >
        <span style={{ fontSize: "12px" }}>Page {pageNumber}</span>
      </div>
    </div>
  );

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("fromdate")}</strong>,
      selector: "fromDate",
      cell: (row) => (
        <div
          title={
            row.fromDate ? `${moment(row.fromDate).format("DD/MM/YYYY")}` : "--"
          }
        >
          {row.fromDate ? moment(row.fromDate).format("DD/MM/YYYY") : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("todate")}</strong>,
      selector: "toDate",
      cell: (row) => (
        <div
          title={
            row.toDate ? `${moment(row.toDate).format("DD/MM/YYYY")}` : "--"
          }
        >
          {row.toDate ? moment(row.toDate).format("DD/MM/YYYY") : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalrefills")}</strong>,
      selector: "totalRefills",
      cell: (row) => (
        <div title={`${row.totalRefills}`}>{row.totalRefills}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalrefillsamount")}</strong>,
      selector: "totalRefillAmount",
      cell: (row) => (
        <div
          title={`€${
            row.totalRefillAmount - row.totalCommisionAmount !== 0
              ? ReplaceFunction(
                  (
                    row.totalRefillAmount?.toFixed(2) -
                    row.totalCommisionAmount?.toFixed(2)
                  ).toFixed(2)
                )
              : row.totalRefillAmount - row.totalCommisionAmount
          }`}
        >
          {/* <div title={`€${row.totalRefillAmount - row.totalCommisionAmount}`}> */}
          {`€${
            row.totalRefillAmount - row.totalCommisionAmount !== 0
              ? ReplaceFunction(
                  (
                    row.totalRefillAmount?.toFixed(2) -
                    row.totalCommisionAmount?.toFixed(2)
                  ).toFixed(2)
                )
              : row.totalRefillAmount - row.totalCommisionAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("totalcommissionamount")}</strong>
      ),
      selector: "totalCommisionAmount",
      cell: (row) => (
        <div
          title={`€${
            row.totalCommisionAmount
              ? ReplaceFunction(row.totalCommisionAmount?.toFixed(2))
              : row.totalCommisionAmount
          }`}
        >
          {`€${
            row.totalCommisionAmount
              ? ReplaceFunction(row.totalCommisionAmount?.toFixed(2))
              : row.totalCommisionAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("expirydate")}</strong>,
      selector: "totalCommisionAmount",
      cell: (row) => (
        <div
          title={`${
            row.expiryDate ? moment(row.expiryDate).format("DD/MM/YYYY") : "--"
          }`}
        >
          {row.expiryDate ? moment(row.expiryDate).format("DD/MM/YYYY") : "--"}
        </div>
      ),
      sortable: true,
    },

    // Changes 06/12/2024
    // {
    //   name: <strong className="tx-uppercase">{t("status")}</strong>,
    //   selector: "status",
    //   cell: (row) => (
    //     <div className="d-flex">
    //       {row.status === "PENDING" && (
    //         <div className="select-pending">
    //           <button
    //             className="btn btn-warning btn-sm"
    //             style={{
    //               width: "110px",
    //               textAlign: "center",
    //               cursor: "default",
    //             }}
    //           >
    //             <i class="fa fa-hourglass"></i> {t("pending")}
    //           </button>
    //         </div>
    //       )}
    //       {row.status === "APPROVED" && (
    //         <button
    //           className="btn btn-success btn-sm"
    //           style={{
    //             width: "110px",
    //             textAlign: "center",
    //             cursor: "default",
    //           }}
    //         >
    //           <i class="fa fa-check"></i> {t("approved")}
    //         </button>
    //       )}
    //     </div>
    //   ),
    //   sortable: true,
    // },
    {
      name: <strong className="tx-uppercase">{t("action")}</strong>,
      selector: "action",
      cell: (row) => (
        <div className="d-flex">
          <div className="me-2">
            <Link
              className="btn btn-primary btn-sm"
              onClick={() => viewPDF(row.id)}
            >
              <i className="fe fe-eye"></i>
            </Link>
          </div>
          {/* <a href={DealerReceipt} download="DealerReceipt">
            <button className="btn btn-primary btn-sm">
              <i className="fa fa-download"></i>
            </button>
          </a> */}
          <div>
            <ExportPDF id={row.id} />
          </div>
          {/* <a href={DealerReceiptXML} download="DealerReceipt">
            <button className="btn btn-primary btn-sm ms-2">
              <i class="fa fa-code"></i>
            </button>
          </a> */}
        </div>
      ),
      sortable: true,
      minWidth: "160px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "160px",
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
      },
    },
    cells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "160px",
        },
      },
    },
    rows: {
      style: {
        ":hover": {
          backgroundColor: "#EEEEEE !important",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#fafafa", // Set the background color for even rows
        },
        "&:nth-child(even)": {
          backgroundColor: "#FFFFFF", // Set the background color for odd rows
        },
        "&:nth-child(even) > :last-child": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :last-child": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even) > :nth-last-child(2)": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :nth-last-child(2)": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on even rows
          },
        },
        "&:nth-child(odd):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on odd rows
          },
        },
      },
    },
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">{t("myreceipts")}</h2>
      <Card>
        {isDialogOpen && (
          <AmountDialog show={isDialogOpen} onHide={handleDialogClose} />
        )}
        {openPDF && (
          <PreviewPDF
            show={openPDF}
            onHide={() => setOpenPDF(false)}
            onDocumentTermsLoadSuccess={onDocumentTermsLoadSuccess}
            numPagesTerms={numPagesTerms}
            PageWithNumber={PageWithNumber}
            userDetails={userDetails}
            invoiceDetail={invoiceDetail}
            adminDetails={adminDetails}
          />
        )}
        {/* <div className="d-flex add-search mt-3 me-3">
          <div className="d-flex align-items-center md-form mt-auto ms-auto">
            <div className="input-group">
              <input
                type="search"
                className="form-control form-control-md py-3"
                value={searchString}
                onChange={handlesearch}
                placeholder={t("search") + "..."}
              />
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="p-3">
          <DataTable
            title="Free Dealer Invoice"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={customStyles}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(FreeDealerInvoices);

const AmountDialog = (props) => {
  const { t } = useTranslation("common");
  const paypal = useRef();
  const [value, setValue] = useState("");

  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const handleOpen = () => {
    handleDialogOpen();
  };

  const handleValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">{t("amount")}</h5>
      </Modal.Header>

      <Modal.Body>
        {/* <div ref={paypal}></div> */}
        {/* <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger mg-t-20"></i> */}

        <Row className="row-sm">
          <Form.Group className="form-group">
            <Form.Label className="text-dark">{t("amount")} :</Form.Label>
            <Form.Control
              type="number"
              name="value"
              value={value}
              onChange={(e) => handleValue(e)}
              placeholder={t("amount")}
              style={{ borderColor: "#d3d3de" }}
            />{" "}
          </Form.Group>
          <div className="d-flex">
            <button
              className="btn btn-primary btn-sm ms-auto"
              onClick={handleOpen}
            >
              {t("paynow")}
            </button>
            {isDialogOpen && (
              <AddAmount
                show={isDialogOpen}
                onHide={handleDialogClose}
                value={value}
              />
            )}
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const AddAmount = (props) => {
  const paymentService = PaymentService();
  const { t } = useTranslation("common");
  const { value } = props;

  const neosStorageString = localStorage.getItem("neos-storage");
  const neosStorage = JSON.parse(neosStorageString);

  const userId = neosStorage && neosStorage.id;

  useEffect(() => {
    window.paypal
      ?.Buttons({
        // Set up the transaction
        createOrder: (data, actions, err) => {
          return actions.order
            .create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  description: "Amount Transfered",
                  amount: {
                    currency_code: "EUR",
                    value: value,
                  },
                },
              ],
            })
            .then(async (id) => {
              const response = await paymentService.createOrder({
                amount: value,
                orderId: id,
                userId: userId,
              });

              return id;
            });
        },

        // Finalize the transaction
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();

          return actions.order.capture().then(function (details) {
            // Show a success message to the buyer

            // alert(
            //   "Transaction completed by " + details.payer.name.given_name + "!"
            // );
            enqueueSnackbar(
              "Transaction completed by " + details.payer.name.given_name + "!",
              {
                anchorOrigin: ANCHORORIGIN,
                variant: SUCCESS,
              }
            );
          });
        },
        onerror: (err) => {},
      })
      // .render(paypal.current);
      .render("#paypal-button-container");
  }, []);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("add") + " " + t("amount")}
        </h5>
      </Modal.Header>

      <Modal.Body className="text-center p-0 pb-4">
        <div id="paypal-button-container" className="m-3" />
      </Modal.Body>
    </Modal>
  );
};
