import React, { useEffect, useState } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import PhoneInput from "react-phone-input-2";
import Multiselect from "react-select";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import BusinessPartnerService from "../../services/businessPartner.service";
import withLoader from "../../layout/Loader/WithLoader";
import { ScrollToTop } from "../../utils/ScrollTop";
import { SUCCESSFULLY_BUSINESSPARTNER_UPDATED } from "../../utils/messages";
import Enums from "../../utils/enums";
import CityService from "../../services/city.service";
import {
  ANCHORORIGIN,
  ERROR,
  FORMIKERROR,
  SUCCESS,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";
import useMenuState from "../../hooks/useMenuState";

const BusinessPartnerProfile = () => {
  const cityService = CityService();
  const businessPartnerService = BusinessPartnerService();
  const { ADDRESS_TYPE, PREFERRED_PAYMENT } = Enums();
  const { t } = useTranslation("common");
  const { id } = useParams();
  const [data, setData] = useState("");
  const [update, setUpdate] = useState(true);
  const [newCity, setNewCity] = useState([]);
  const [vatNumber, setVatNumber] = useState("");
  const [newAddressCity, setNewAddressCity] = useState("");
  const [newAddressType, setNewAddressType] = useState("");
  const [addNumbers, setAddNumbers] = useState(1);
  const [addAddressSlot, setAddAddressSlot] = useState(1);
  const [cities, setCities] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [isShowStreet, setIsShowStreet] = useState(false);
  const [faxNumber, setFaxNumber] = useState("+39");
  const maxNumberSize = 15;

  const [openPaymentType, handleOpenPaymentType, handleClosePaymentType] =
    useMenuState(false);

  const handleFaxNumberChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    if (sanitizedInput.length > maxNumberSize) {
      return;
    }
    let formattedNumber = "";
    let countryCode = sanitizedInput.slice(0, 2);
    for (let i = 0; i < sanitizedInput.length; i++) {
      if (i === 2 || i === 5) {
        formattedNumber += ` ${sanitizedInput.charAt(i)}`;
      } else {
        formattedNumber += sanitizedInput.charAt(i);
      }
    }

    setFaxNumber(formattedNumber);
    formik.setFieldValue("countryCode", countryCode);
    formik.setFieldValue("fax", formattedNumber);
  };

  const handleVAT = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    setVatNumber(sanitizedInput);
    formik.setFieldValue("vatNo", sanitizedInput);
  };

  // Add address slot
  const addAddressData = () => {
    // Empty address slot with empty field data
    formik.setFieldValue("addressList", [
      ...formik.values.addressList,
      {
        city: "",
        id: 0,
        pincode: "",
        state: "",
        street: "",
        type: null,
      },
    ]);
    setAddAddressSlot(addAddressSlot + 1);
  };

  // remove slot by index
  const removeAddress = (index) => {
    if (addAddressSlot > 1) {
      formik.setFieldValue(
        "addressList",
        formik.values.addressList.filter((_, i) => i !== index)
      );
      setAddAddressSlot(addAddressSlot - 1);
    }
  };

  // Add number slot
  const addNumber = () => {
    // Empty number slot with empty field data
    formik.setFieldValue("contanctNumbers", [
      ...formik.values.contanctNumbers,
      {
        bpId: 0,
        countryCode: "",
        id: 0,
        phoneNo: "",
      },
    ]);
    setAddNumbers(addNumbers + 1);
  };

  // remove slot by index
  const removeNumber = (index) => {
    if (addNumbers > 1) {
      formik.setFieldValue(
        "contanctNumbers",
        formik.values.contanctNumbers.filter((_, i) => i !== index)
      );
      setAddNumbers(addNumbers - 1);
    }
  };

  // Get all city names
  const getAllCity = async () => {
    const response = await cityService.getAllPageCity();
    setNewCity(response.data.apiresponse.data);
  };

  // Set city names for dropdown
  const city = [
    ...newCity.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  ];

  // Select city when select from the dropdown ny index
  const selectCity = async (index, selectedOption) => {
    const id = selectedOption.value;
    formik.setFieldValue(`addressList[${index}].city`, selectedOption.label);

    const response = await cityService.getCity(id);
    formik.setFieldValue(
      `addressList[${index}].state`,
      response.data.apiresponse.data.state
    );
  };

  // Handle phone format change ny index
  const handleChangePhone = (index, country, value) => {
    if (country.startsWith(value.dialCode)) {
      const number = country.slice(value.dialCode.length);

      formik.setFieldValue(`contanctNumbers[${index}].phoneNo`, number);
      formik.setFieldValue(
        `contanctNumbers[${index}].countryCode`,
        value.dialCode
      );
    }
  };

  const handleInvoiceCode = (e) => {
    const uppercase = e.target.value.toUpperCase();
    if (uppercase.length > 7) {
      return;
    }
    formik.setFieldValue("einvoiceCode", uppercase);
  };

  const handlePincodeChange = (e, index) => {
    const input = e.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    formik.setFieldValue(`addressList[${index}].pincode`, sanitizedInput);
  };

  // Add & update fields
  const getBusinessPartner = async () => {
    const response = await businessPartnerService.getBusinessPartner(id);
    setData(response.data.apiresponse.data);
    ScrollToTop();

    // set field values for the business partner

    const result = response.data.apiresponse.data;

    setCities(
      result.cityAssignement.map((res) => res.cityDetail.name).join(", ")
    );
    setIsShowStreet(result.showStreet);
    setNewAddressType(result.addressList.forEach((address) => address.type));
    formik.setFieldValue("id", result.id);
    formik.setFieldValue("commercialTelephone", result.commercialTelephone);
    formik.setFieldValue("commercialSurname", result.commercialSurname);
    formik.setFieldValue("commercialEmail", result.commercialEmail);
    formik.setFieldValue(
      "administrativeTelephone",
      result.administrativeTelephone
    );
    formik.setFieldValue("administrativeSurname", result.administrativeSurname);
    formik.setFieldValue("administrativeEmail", result.administrativeEmail);
    formik.setFieldValue("technicalTelephone", result.technicalTelephone);
    formik.setFieldValue("technicalSurname", result.technicalSurname);
    formik.setFieldValue("technicalEmail", result.technicalEmail);
    formik.setFieldValue("name", result.name);
    formik.setFieldValue("email", result.email);
    formik.setFieldValue("certificateEmail", result.certificateEmail);
    formik.setFieldValue("cig", result.cig);
    formik.setFieldValue("showStreet", result.showStreet);
    formik.setFieldValue("vatNo", result.vatNo);
    setVatNumber(result.vatNo);

    // Set city names
    const cityNames = result.cityAssignement.map((res) => res.cityDetail);
    const cityValues = cityNames.map((cityName) => ({
      value: cityName.id,
      label: cityName.name,
    }));
    formik.setFieldValue("cityIds", cityValues);

    // Set contactlist by index
    result.contactList.forEach((contact, index) => {
      formik.setFieldValue(
        `contanctNumbers[${index}].phoneNo`,
        contact.phoneNo
      );
      formik.setFieldValue(
        `contanctNumbers[${index}].countryCode`,
        contact.countryCode
      );
    });
    setPhoneNumber(result.contactList.map((res) => res));
    setAddAddressSlot(result.addressList.length);

    const formatNumber = (number) => {
      const digits = Number(number).toString();
      const areaCode = digits.substring(0, 3);
      const firstPart = digits.substring(3, 10);

      setFaxNumber(`${result.countryCode} ${areaCode} ${firstPart}`);

      if (digits.length !== 10) {
        return "Invalid number";
      }

      formik.setFieldValue(
        "fax",
        `${result.countryCode} ${areaCode} ${firstPart}`
      );
      formik.setFieldValue("countryCode", result.countryCode);

      return `${result.countryCode} ${areaCode} ${firstPart}`;
    };
    formatNumber(result.fax);

    formik.setFieldValue("note", result.note);
    formik.setFieldValue("einvoiceCode", result.einvoiceCode);
    formik.setFieldValue("taxCode", result.taxCode);
    formik.setFieldValue("fax", result.fax);
    formik.setFieldValue("countryCode", result.countryCode);
    formik.setFieldValue("preferredPaymentType", result.preferredPaymentType);
    formik.setFieldValue("holderName", result.holderName);
    formik.setFieldValue("bankIBAN", result.bankIBAN);

    setAddAddressSlot(result.addressList.length);
    setAddNumbers(result.contactList.length);
    result.addressList.forEach((address, index) => {
      setNewAddressType(address.type);
      setNewAddressCity(address.city);
      formik.setFieldValue(`addressList[${index}].type`, address.type);
      formik.setFieldValue(`addressList[${index}].city`, address.city);
      formik.setFieldValue(`addressList[${index}].street`, address.street);
      formik.setFieldValue(`addressList[${index}].state`, address.state);
      formik.setFieldValue(`addressList[${index}].pincode`, address.pincode);
    });
  };

  // Address schema for validation
  const addressSchema = yup.object().shape({
    type: yup.mixed().required("Type is required"),
    city: yup.mixed().required("City is required"),
    pincode: yup
      .string()
      .max(5, "Maximum 5 numbers!")
      .min(5, "Minimum 5 numbers!")
      .required("Please Enter Pincode!"),
    state: yup.string().required("State is required"),
    street: yup.string().required("Street is required"),
  });

  // Contact schema for validation
  const contactSchema = yup.object().shape({
    countryCode: yup.string().required("Country Code is required"),
    phoneNo: yup.string().required("Phone Number is required"),
  });

  // Initial values for form
  const initialValue = {
    name: "",
    addressList: [
      {
        city: "",
        id: 0,
        pincode: "",
        state: "",
        street: "",
        type: null,
      },
    ],
    administrativeEmail: "",
    administrativeSurname: "",
    administrativeTelephone: "",
    bankIBAN: "",
    certificateEmail: "",
    cityIds: [],
    commercialEmail: "",
    commercialSurname: "",
    commercialTelephone: "",
    contanctNumbers: [
      {
        bpId: 0,
        countryCode: "",
        id: 0,
        phoneNo: "",
      },
    ],
    countryCode: "",
    einvoiceCode: "",
    fax: "",
    holderName: "",
    note: "",
    preferredPaymentType: null,
    taxCode: "",
    technicalEmail: "",
    technicalSurname: "",
    technicalTelephone: "",
    vatNo: "",
    showStreet: null,
    cig: "",
    email: "",
  };

  // Validation for form
  const validationSchema = yup.object().shape({
    commercialSurname: yup.string(),
    commercialEmail: yup.string().email("Please enter Valid Commercial Email"),
    commercialTelephone: yup.string().min(8, "Min 8 digits Requires"),

    technicalSurname: yup.string(),
    technicalEmail: yup.string().email("Please enter Valid Technical Email"),
    technicalTelephone: yup.string().min(8, "Min 8 digits Requires"),

    administrativeSurname: yup.string(),
    administrativeEmail: yup
      .string()
      .email("Please enter Valid Administrative Email"),
    administrativeTelephone: yup.string().min(8, "Min 8 digits Requires"),

    einvoiceCode: yup
      .string()
      .matches(/^[A-Z0-9]+$/, "It contains only UPPERCASE letters and Numbers")
      .max(7, "Must be at most 7 characters"),

    name: yup.string().required("Please enter Name!"),
    vatNo: yup
      .string()
      .matches(/^\d{11}$/, "Please enter exactly 11 digits")
      .required("VAT number is required"),

    // pincode: yup
    //   .string()
    //   .max(5, "Maximum 5 numbers!")
    //   .min(5, "Minimum 5 numbers!")
    //   .required("Please Enter Pincode!"),
    email: yup.string().email().required(),
    certificateEmail: yup.string().email(),
    contanctNumbers: yup.array().of(contactSchema),
    addressList: yup.array().of(addressSchema),
    cityIds: yup.array().min(1, "Select at least one city"),
  });

  // Submit form and update API call
  const handleSubmit = async (values, action) => {
    const {
      id,
      name,
      email,
      commercialSurname,
      commercialEmail,
      commercialTelephone,
      technicalSurname,
      technicalEmail,
      technicalTelephone,
      administrativeSurname,
      administrativeEmail,
      administrativeTelephone,
      vatNo,
      certificateEmail,
      einvoiceCode,
      contanctNumbers,
      holderName,
      bankIBAN,
      cityIds,
      addressList,
      fax,
      preferredPaymentType,
      taxCode,
      note,
      countryCode,
      showStreet,
      cig,
    } = values;

    const ids = cityIds.map((id) => (id.value ? id.value : id));
    const sanitizedFaxNumber = fax.replace(/[^\d]/g, "").substring(2);

    try {
      const response = await businessPartnerService.updateBusinessPartner(id, {
        name,
        email,
        commercialSurname,
        commercialEmail,
        commercialTelephone,
        technicalSurname,
        technicalEmail,
        technicalTelephone,
        administrativeSurname,
        administrativeEmail,
        administrativeTelephone,
        vatNo,
        certificateEmail,
        einvoiceCode,
        contanctNumbers,
        holderName,
        bankIBAN,
        cityIds: ids,
        addressList,
        fax: sanitizedFaxNumber,
        preferredPaymentType,
        taxCode,
        note,
        countryCode,
        showStreet,
        cig,
      });
      getBusinessPartner();
      setUpdate(!update);

      enqueueSnackbar(t("SUCCESSFULLY_BUSINESSPARTNER_UPDATED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });

      action.resetForm();
    } catch (error) {
      const errorResponse = error.response.data;

      if (
        errorResponse &&
        errorResponse.apierror &&
        errorResponse.apierror.subErrors
      ) {
        const errorMessages = errorResponse.apierror.subErrors.map(
          (subError) => subError.message
        );

        errorMessages.forEach((errorMessage) => {
          enqueueSnackbar(errorMessage, {
            variant: ERROR,
            anchorOrigin: ANCHORORIGIN,
          });
        });
      } else {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    }
  };

  const handleshowStreet = (e) => {
    const isChecked = e.target.checked;

    setIsShowStreet(isChecked);
    formik.setFieldValue(`showStreet`, isChecked);
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const { fax, countryCode, addressList } = data;

  useEffect(() => {
    getBusinessPartner();
    formatNumber(fax);
    getAllCity();
  }, []);

  const edit = () => {
    setUpdate(!update);
  };

  // Format number
  const formatNumber = (number) => {
    const digits = Number(number).toString();

    if (digits.length !== 10) {
      return "Invalid number";
    }

    const areaCode = digits.substring(0, 2);
    const firstPart = digits.substring(2, 10);

    return `+${countryCode}.${areaCode}.${firstPart}`;
  };

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">
          {data.name} {t("profile")}
        </h2>
        <form onSubmit={formik.handleSubmit}>
          {update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="button"
              onClick={edit}
            >
              <i className="fe fe-edit me-1"></i> {t("edit")}
            </button>
          )}
          {!update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="submit"
            >
              <i className="fas fa-save me-1"></i> {t("save")}
            </button>
          )}
          <Row className="row row-sm">
            <Col sm={12} lg={12} xl={12}>
              <Card className="custom-card">
                <Card.Body>
                  <div className="row">
                    <h5 className="text-dark mb-3">{t("personaldetails")} :</h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("name")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.name && formik.touched.name ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.name}
                            </Form.Label>
                          ) : null}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            disabled={update ? true : false}
                          />
                          {formik.errors.email && formik.touched.email ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.email}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("certifateemail")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="certificateEmail"
                            onChange={formik.handleChange}
                            value={formik.values.certificateEmail}
                            disabled={update ? true : false}
                          />
                          {formik.errors.certificateEmail &&
                          formik.touched.certificateEmail ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.certificateEmail}
                            </Form.Label>
                          ) : null}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("cig")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="cig"
                            onChange={formik.handleChange}
                            value={formik.values.cig}
                            disabled={update ? true : false}
                          />
                          {formik.errors.cig && formik.touched.cig ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.cig}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("mobilenumber")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          {!update && (
                            <>
                              {[...Array(addNumbers)].map((_, index) => (
                                <>
                                  <div key={index} className="d-flex mb-2">
                                    <PhoneInput
                                      country={"it"}
                                      value={`${formik.values.contanctNumbers[index]?.countryCode} ${formik.values.contanctNumbers[index]?.phoneNo}`}
                                      onChange={(country, value) =>
                                        handleChangePhone(index, country, value)
                                      }
                                      inputStyle={{ width: "inherit" }}
                                    />
                                    {index === addNumbers - 1 ? (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm ms-2"
                                          onClick={addNumber}
                                        >
                                          <i className="fa fa-plus"></i>
                                        </button>
                                        {index > 0 && (
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger ms-2"
                                            onClick={() => removeNumber(index)}
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        )}
                                      </>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-danger ms-2"
                                        onClick={() => removeNumber(index)}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    )}
                                  </div>
                                  {formik.errors.contanctNumbers?.[index] &&
                                  formik.touched.contanctNumbers?.[index] ? (
                                    <Form.Label style={FORMIKERROR}>
                                      {
                                        (formik.errors.contanctNumbers?.[index]
                                          ?.countryCode,
                                        formik.errors.contanctNumbers?.[index]
                                          ?.phoneNo)
                                      }
                                    </Form.Label>
                                  ) : null}
                                </>
                              ))}
                            </>
                          )}
                          {update && (
                            <Form.Control
                              type="text"
                              name="phoneNumber"
                              value={phoneNumber
                                ?.map(
                                  (res) =>
                                    "+" + res.countryCode + " " + res.phoneNo
                                )
                                .join(", ")}
                              disabled
                            />
                          )}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("vatnumber")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="vatNo"
                            onChange={handleVAT}
                            value={vatNumber}
                            disabled={update ? true : false}
                          />
                          {formik.errors.vatNo && formik.touched.vatNo ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.vatNo}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("city")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          {/* {update && ( */}
                          <Form.Control
                            type="text"
                            name="cityAssignement"
                            value={cities}
                            disabled
                          />
                          {/* )}
                          {!update && (
                            <Select
                              classNamePrefix="Select2"
                              closeMenuOnSelect={false}
                              isMulti
                              options={city}
                              name="cityIds"
                              value={formik.values.cityIds}
                              onChange={(selectedOption) =>
                                formik.setFieldValue("cityIds", selectedOption)
                              }
                              placeholder="Select City"
                            />
                          )} */}
                          {/* {formik.errors.cityIds && formik.touched.cityIds ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.cityIds}
                            </Form.Label>
                          ) : null} */}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("note")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="note"
                            onChange={formik.handleChange}
                            value={formik.values.note}
                            disabled={update ? true : false}
                          />
                          {formik.errors.note && formik.touched.note ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.note}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("eleinvoicecode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="einvoiceCode"
                            onChange={handleInvoiceCode}
                            value={formik.values.einvoiceCode}
                            disabled={update ? true : false}
                          />
                          {formik.errors.einvoiceCode &&
                          formik.touched.einvoiceCode ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.einvoiceCode}
                            </Form.Label>
                          ) : null}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("taxcode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="taxCode"
                            onChange={formik.handleChange}
                            value={formik.values.taxCode}
                            disabled={update ? true : false}
                          />
                          {formik.errors.taxCode && formik.touched.taxCode ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.taxCode}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("fax")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          {update && (
                            <Form.Control
                              type="text"
                              name="fax"
                              onChange={formik.handleChange}
                              value={formik.values.fax}
                              disabled={update ? true : false}
                            />
                          )}
                          {!update && (
                            <Form.Control
                              type="text"
                              value={faxNumber}
                              onChange={handleFaxNumberChange}
                              name="fax"
                              placeholder="FAX +39.00.12345678"
                              style={{ borderColor: "#d3d3de" }}
                            />
                          )}
                          {formik.errors.fax && formik.touched.fax && (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.fax}
                            </Form.Label>
                          )}
                        </Col>{" "}
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("preferredpaymenttype")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          {update && (
                            <Form.Control
                              type="text"
                              name="preferredPaymentType"
                              onChange={formik.handleChange}
                              value={formik.values.preferredPaymentType}
                              disabled
                            />
                          )}
                          {!update && (
                            <Multiselect
                              classNamePrefix="Select2"
                              options={PREFERRED_PAYMENT}
                              singleSelect
                              placeholder={t("preferredpaymenttype")}
                              displayValue="label"
                              name="preferredPaymentType"
                              menuIsOpen={openPaymentType}
                              onMenuOpen={handleOpenPaymentType}
                              onMenuClose={handleClosePaymentType}
                              value={PREFERRED_PAYMENT.find(
                                (option) =>
                                  option.value ===
                                  formik.values.preferredPaymentType
                              )}
                              onChange={(selectedOption) =>
                                formik.setFieldValue(
                                  "preferredPaymentType",
                                  selectedOption.value
                                )
                              }
                            />
                          )}
                          {formik.errors.preferredPaymentType &&
                          formik.touched.preferredPaymentType ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.preferredPaymentType}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("ccholdername")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="holderName"
                            onChange={formik.handleChange}
                            value={formik.values.holderName}
                            disabled={update ? true : false}
                          />
                          {formik.errors.holderName &&
                          formik.touched.holderName ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.holderName}
                            </Form.Label>
                          ) : null}
                        </Col>{" "}
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("bankaccountIBAN")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="bankIBAN"
                            onChange={formik.handleChange}
                            value={formik.values.bankIBAN}
                            disabled={update ? true : false}
                          />
                          {formik.errors.bankIBAN && formik.touched.bankIBAN ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.bankIBAN}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        {update && (
                          <label className="custom-checkbox d-flex">
                            <span className="tx-16">
                              <Form.Label>
                                {t("doyouwanttoallowshowstreet")} :
                              </Form.Label>
                            </span>
                            <input
                              type="checkbox"
                              className="custom-control-checkbox tx-16"
                              name={`example-checkbox`}
                              value="option1"
                              defaultChecked={formik.values.showStreet}
                              disabled
                            />
                          </label>
                        )}
                        {!update && (
                          <label className="custom-checkbox d-flex">
                            <span className="tx-16">
                              {t("doyouwanttoallowshowstreet")}
                            </span>
                            <input
                              type="checkbox"
                              className="custom-control-checkbox tx-16"
                              name={`example-checkbox`}
                              value="option1"
                              defaultChecked={isShowStreet}
                              onChange={(e) => {
                                handleshowStreet(e);
                              }}
                            />
                          </label>
                        )}
                      </Row>
                    </FormGroup>

                    <hr className="mt-3" />
                    {update && (
                      <>
                        {addressList &&
                          addressList.map((address, index) => (
                            <>
                              <div key={index}>
                                <h5 className="text-dark mb-3 tx-uppercase">
                                  {t("address")} {index + 1} :
                                </h5>

                                <FormGroup className="form-group">
                                  <Row className=" row-sm">
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label>
                                        {t("address") + " " + t("type")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          formik.values.addressList[index]?.type
                                        }
                                        onChange={formik.handleChange}
                                        disabled
                                      />
                                    </Col>
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label className="text-dark">
                                        {t("province")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          formik.values.addressList[index]
                                            ?.state
                                        }
                                        onChange={formik.handleChange}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup className="form-group">
                                  <Row className=" row-sm">
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label>
                                        {t("streetaddress")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          formik.values.addressList[index]
                                            ?.street
                                        }
                                        onChange={formik.handleChange}
                                        disabled
                                      />
                                    </Col>
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label className="text-dark">
                                        {t("pincode")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          formik.values.addressList[index]
                                            ?.pincode
                                        }
                                        onChange={formik.handleChange}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup className="form-group">
                                  <Row className=" row-sm">
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label className="text-dark">
                                        {t("city")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          formik.values.addressList[index]?.city
                                        }
                                        onChange={formik.handleChange}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>

                                <hr className="mt-3" />
                              </div>
                            </>
                          ))}
                      </>
                    )}

                    {!update && (
                      <>
                        {[...Array(addAddressSlot)].map((_, index) => (
                          <>
                            <div key={index}>
                              <div className="d-flex">
                                <h5 className="text-dark tx-uppercase">
                                  {t("address")}. {index + 1}
                                </h5>
                                <div className="ms-auto mb-1">
                                  {index === addAddressSlot - 1 ? (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm ms-2"
                                        onClick={addAddressData}
                                      >
                                        <i className="fa fa-plus"></i>
                                      </button>
                                      {index > 0 && (
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger ms-2"
                                          onClick={() => removeAddress(index)}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-danger ms-2"
                                      onClick={() => removeAddress(index)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  )}
                                </div>
                              </div>

                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label>
                                      {t("address") + " " + t("type")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Multiselect
                                      classNamePrefix="Select2"
                                      options={ADDRESS_TYPE}
                                      singleSelect
                                      placeholder="Address Type"
                                      displayValue="key"
                                      name={`addressList[${index}].type`}
                                      defaultInputValue={newAddressType}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          `addressList[${index}].type`,
                                          selectedOption.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("province")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="text"
                                      value={
                                        formik.values.addressList[index].state
                                      }
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          `addressList[${index}].state`,
                                          e.target.value
                                        )
                                      }
                                      name={`addressList[${index}].state`}
                                      placeholder="State"
                                      style={{ borderColor: "#d3d3de" }}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label>
                                      {t("streetaddress")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="text"
                                      value={
                                        formik.values.addressList[index].street
                                      }
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          `addressList[${index}].street`,
                                          e.target.value
                                        )
                                      }
                                      name={`addressList[${index}].street`}
                                      placeholder="Street Address"
                                      style={{ borderColor: "#d3d3de" }}
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("pincode")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="text"
                                      value={
                                        formik.values.addressList[index].pincode
                                      }
                                      onChange={(e) =>
                                        handlePincodeChange(e, index)
                                      }
                                      name={`addressList[${index}].pincode`}
                                      placeholder="Pincode"
                                      style={{ borderColor: "#d3d3de" }}
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("city")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Multiselect
                                      classNamePrefix="Select2"
                                      options={city}
                                      singleSelect
                                      placeholder="City"
                                      displayValue="key"
                                      defaultInputValue={newAddressCity}
                                      name={`addressList[${index}].city`}
                                      onChange={(selectedOption) =>
                                        selectCity(index, selectedOption)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>

                              <hr className="mt-3" />
                            </div>
                          </>
                        ))}
                      </>
                    )}

                    <h5 className="text-dark mb-3 tx-uppercase">
                      {t("commercial")} :
                    </h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("surname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="commercialSurname"
                            onChange={formik.handleChange}
                            value={formik.values.commercialSurname}
                            disabled={update ? true : false}
                          />
                          {formik.errors.commercialSurname &&
                          formik.touched.commercialSurname ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.commercialSurname}
                            </Form.Label>
                          ) : null}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="commercialEmail"
                            onChange={formik.handleChange}
                            value={formik.values.commercialEmail}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.commercialEmail &&
                          formik.touched.commercialEmail ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.commercialEmail}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("telephone")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="commercialTelephone"
                            onChange={formik.handleChange}
                            value={formik.values.commercialTelephone}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.commercialTelephone &&
                          formik.touched.commercialTelephone ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.commercialTelephone}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr className="mt-3" />

                    <h5 className="text-dark mb-3 tx-uppercase">
                      {t("technical")} :
                    </h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("surname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="technicalSurname"
                            onChange={formik.handleChange}
                            value={formik.values.technicalSurname}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.technicalSurname &&
                          formik.touched.technicalSurname ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.technicalSurname}
                            </Form.Label>
                          ) : null}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="technicalEmail"
                            onChange={formik.handleChange}
                            value={formik.values.technicalEmail}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.technicalEmail &&
                          formik.touched.technicalEmail ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.technicalEmail}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("telephone")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="technicalTelephone"
                            onChange={formik.handleChange}
                            value={formik.values.technicalTelephone}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.technicalTelephone &&
                          formik.touched.technicalTelephone ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.technicalTelephone}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr className="mt-3" />

                    <h5 className="text-dark mb-3 tx-uppercase">
                      {t("administrative")} :
                    </h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("surname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="administrativeSurname"
                            onChange={formik.handleChange}
                            value={formik.values.administrativeSurname}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.administrativeSurname &&
                          formik.touched.administrativeSurname ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.administrativeSurname}
                            </Form.Label>
                          ) : null}
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="administrativeEmail"
                            onChange={formik.handleChange}
                            value={formik.values.administrativeEmail}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.administrativeEmail &&
                          formik.touched.administrativeEmail ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.administrativeEmail}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("telephone")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="administrativeTelephone"
                            onChange={formik.handleChange}
                            value={formik.values.administrativeTelephone}
                            disabled={update ? true : false}
                          />{" "}
                          {formik.errors.administrativeTelephone &&
                          formik.touched.administrativeTelephone ? (
                            <Form.Label style={FORMIKERROR}>
                              {formik.errors.administrativeTelephone}
                            </Form.Label>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </Fragment>
  );
};

export default withLoader(BusinessPartnerProfile);
