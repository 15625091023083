import React, { useEffect, useRef, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import withLoader from "../../layout/Loader/WithLoader";
import PreviewPDF from "./PreviewPDF";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs, Page } from "react-pdf";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "react-select";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Enums from "../../utils/enums";
import ExcelJS from "exceljs";
import {
  ANCHORORIGIN,
  CUSTOMDROPDOWNSTYLE,
  CUSTOM_STYLES,
  ERROR,
  PAGELIMIT,
  ReplaceFunction,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";
import InvoiceService from "../../services/invoice.service";
import { Select } from "antd";
import ExportPDF from "./ExportPDF";
import UserService from "../../services/user.service";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Invoices = () => {
  const { state } = useLocation();
  const invoiceService = InvoiceService();
  const userService = UserService();
  const { t } = useTranslation("common");
  const { FILTERROLE, RANGES } = Enums();
  const searchingName = state?.dealername;
  const localStorageData = localStorage.getItem("neos-storage");
  const neosStorage = JSON.parse(localStorageData);

  const [openPDF, setOpenPDF] = useState(false);
  const tableRef = useRef(null);

  const [totalRows, setTotalRows] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [newRole, setNewRole] = useState("");
  const [adminDetails, setAdminDetails] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [isDownloadLoading, setIsDownloadLoading] = useState("");

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const getInvoices = async () => {
    try {
      const response = await invoiceService.getAdminInvoice({
        fromDate: new Date(startDate).getTime(),
        pageLimit: dataPerPage,
        pageNumber: pageNumber,
        role: selectedRole,
        searchText: searchText === undefined ? "" : searchText,
        toDate: new Date(endDate).getTime(),
      });

      setData(response.data.apiresponse.data.invoices);
      setTotalRows(response.data.apiresponse.data.totalRecords);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handlesearch = (e) => {
    setSearchText(e.target.value);
    // handleSearchDebounced(e.target.value);
  };

  const PageWithNumber = ({ pageNumber }) => (
    <div style={{ position: "relative" }}>
      <Page pageNumber={pageNumber} width={900} />
      <div
        style={{
          textAlign: "center",
          background: "#FFFFFF",
          opacity: 0.8,
        }}
      >
        <span style={{ fontSize: "12px" }}>Page {pageNumber}</span>
      </div>
    </div>
  );

  useEffect(() => {
    getInvoices();
  }, [startDate, endDate, searchText, selectedRole, pageNumber, dataPerPage]);

  const viewPDF = async (id) => {
    setOpenPDF(true);
    const response = await invoiceService.invoiceDetails(id);
    setInvoiceDetail(response.data.apiresponse.data.invoiceDetail);
    setUserDetails(response.data.apiresponse.data.userDetail);

    const adminDetailResponse = await userService.getAdmin();
    setAdminDetails(adminDetailResponse.data.apiresponse.data);
  };

  const downloadXML = async (id, row) => {
    try {
      const response = await invoiceService.downloadXML(id);

      const contentDisposition = response.headers.get("Content-Disposition");

      let value = contentDisposition.split("=");

      let fileName = value[1].split(".");

      // Now you can use extractedFilename as needed
      if (response.data) {
        // const zip = new JSZip();
        // zip.file(response.data);

        // const zipData = await zip.generateAsync({
        //   type: "blob",
        //   streamFiles: true,
        // });
        // console.log(zipData);
        // // Create a download link for the zip file
        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(zipData);
        // link.download = "snapcial-ai.zip";
        // link.click();

        const blob = new Blob([response.data], { type: "application/xml" });

        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${row.username}_${row.courtesyReceipt}.xml`;
        a.style.display = "none";

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading XML:", error);
    }
  };

  const handleStatusChange = async (invoiceId) => {
    const response = await invoiceService.confirmInvoice(invoiceId);

    getInvoices();
  };

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: "username",
      cell: (row) => (
        <div title={`${row.username ? row.username : row.companyName}`}>
          {row.username ? row.username : row.companyName}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("role")}</strong>,
      selector: "role",
      cell: (row) => <div title={`${row.role}`}>{row.role}</div>,
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("fromdate")}</strong>,
      selector: "fromdate",
      cell: (row) => (
        <div
          title={
            row.fromdate ? `${moment(row.fromdate).format("DD/MM/YYYY")}` : "--"
          }
        >
          {row.fromdate ? moment(row.fromdate).format("DD/MM/YYYY") : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("todate")}</strong>,
      selector: "todate",
      cell: (row) => (
        <div
          title={
            row.todate ? `${moment.utc(row.todate).format("DD/MM/YYYY")}` : "--"
          }
        >
          {row.todate ? moment.utc(row.todate).format("DD/MM/YYYY") : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("totalparkingsevents")}</strong>
      ),
      selector: "totalStops",
      cell: (row) => <div title={`${row.totalStops}`}>{row.totalStops}</div>,
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalparkingcharge")}</strong>,
      selector: "totalParkingCharge",
      cell: (row) => (
        <div
          title={`€${
            row.totalParkingCharge !== 0
              ? ReplaceFunction(row.totalParkingCharge?.toFixed(2))
              : row.totalParkingCharge
          }`}
        >
          €
          {row.totalParkingCharge !== 0
            ? ReplaceFunction(row.totalParkingCharge?.toFixed(2))
            : row.totalParkingCharge}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalservicefee")}</strong>,
      selector: "totalServiceFee",
      cell: (row) => (
        <div
          title={`€${
            row.totalServiceFee !== 0
              ? ReplaceFunction(row.totalServiceFee?.toFixed(2))
              : row.totalServiceFee
          }`}
        >
          €
          {row.totalServiceFee !== 0
            ? ReplaceFunction(row.totalServiceFee?.toFixed(2))
            : row.totalServiceFee}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalrefills")}</strong>,
      selector: "totalRefills",
      cell: (row) => (
        <div title={`${row.totalRefills}`}>{row.totalRefills}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalrefillsamount")}</strong>,
      selector: "totalRefillAmount",
      cell: (row) => (
        <div
          title={`€${
            row.totalRefillAmount - row.totalCommision !== 0
              ? ReplaceFunction(
                  (
                    row.totalRefillAmount?.toFixed(2) -
                    row.totalCommision?.toFixed(2)
                  ).toFixed(2)
                )
              : row.totalRefillAmount - row.totalCommision
          }`}
        >
          €
          {row.totalRefillAmount - row.totalCommision !== 0
            ? ReplaceFunction(
                (
                  row.totalRefillAmount?.toFixed(2) -
                  row.totalCommision?.toFixed(2)
                ).toFixed(2)
              )
            : row.totalRefillAmount - row.totalCommision}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("totalcommissionamount")}</strong>
      ),
      selector: "totalCommision",
      cell: (row) => (
        <div
          title={`€${
            row.totalCommision !== 0
              ? ReplaceFunction(row.totalCommision?.toFixed(2))
              : row.totalCommision
          }`}
        >
          €
          {row.totalCommision !== 0
            ? ReplaceFunction(row.totalCommision?.toFixed(2))
            : row.totalCommision}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("expirydate")}</strong>,
      selector: "totalCommision",
      cell: (row) => (
        <div
          title={`${
            row.dealerType === "FREE" && row.role === "DEALER"
              ? moment(row.expiryDate).format("DD/MM/YYYY")
              : "--"
          }`}
        >
          {row.dealerType === "FREE" && row.role === "DEALER"
            ? moment(row.expiryDate).format("DD/MM/YYYY")
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("status")}</strong>,
      selector: "totalCommision",
      cell: (row) => (
        <div className="d-flex">
          {row.dealerType === "FREE" && row.role === "DEALER" ? (
            <>
              {row.status === "PENDING" && (
                <div className="select-pending">
                  <Select
                    value={"PENDING"}
                    onChange={(newStatus) => handleStatusChange(row.invoiceId)}
                    style={{ width: "110px" }}
                    className="custom-select-1"
                  >
                    <option
                      value="APPROVED"
                      className="text-white"
                      style={{ backgroundColor: "#15A552" }}
                    >
                      <i className="fa fa-check"></i> {t("approve")}
                    </option>
                    <option
                      value="PENDING"
                      className="d-none"
                      style={{ backgroundColor: "#F1911D" }}
                    >
                      <div>
                        <i className="fa fa-clock"></i> {t("pending")}
                      </div>
                    </option>
                  </Select>
                </div>
              )}
              {row.status === "APPROVED" && (
                <button
                  className="btn btn-success btn-sm"
                  style={{
                    width: "110px",
                    textAlign: "center",
                    cursor: "default",
                  }}
                >
                  <i class="si si-close"></i> {t("approved")}
                </button>
              )}
            </>
          ) : (
            "--"
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("courtesyReceipt")}</strong>,
      selector: "courtesyReceipt",
      cell: (row) => (
        <div title={`${row.courtesyReceipt}`}>{row.courtesyReceipt}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("action")}</strong>,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-2">
            <Link
              className="btn btn-primary btn-sm"
              onClick={() => viewPDF(row.invoiceId)}
            >
              <i className="fe fe-eye"></i>
            </Link>
          </div>

          <div>
            <ExportPDF id={row.invoiceId} row={row} />
          </div>

          {row.role !== "DEALER" && (
            <button
              onClick={() => downloadXML(row.invoiceId, row)}
              className="btn btn-primary btn-sm me-2"
            >
              <i class="fa fa-code"></i>
            </button>
            // </button>
          )}
        </div>
      ),
      sortable: true,
      minWidth: "170px",
    },
  ];

  const exportPDF = async () => {
    setIsDownloadLoading("pdf");
    const response = await invoiceService.getAdminInvoice({
      fromDate: new Date(startDate).getTime(),
      role: selectedRole,
      searchText: searchText === undefined ? "" : searchText,
      toDate: new Date(endDate).getTime(),
    });
    const data = response.data.apiresponse.data.invoices;

    let totalCost = 0;
    let totalStop = 0;
    for (let i = 0; i < data.length; i++) {
      totalCost +=
        data[i].role === "DEALER"
          ? parseFloat(data[i].totalRefillAmount - data[i].totalCommision)
          : parseFloat(data[i].totalParkingCharge);
      totalStop += parseFloat(data[i].totalStops);
    }

    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3, or A4
    const orientation = "landscape"; // portrait or landscape

    const pageWidth = 595.28; // A4 page width in points
    const titleFontSize = 15;

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(titleFontSize);

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 900, 10, 170, 70);

    const title = "FATTURE";
    const titleWidth = doc.getStringUnitWidth(title) * titleFontSize; // Calculate title width in points

    // Calculate the x-coordinate to center the title on the page
    const titleX = (pageWidth - titleWidth) / 2;
    doc.text(title, 40, 40);

    const headers = [
      [
        "RICEVUTA DI CORTESIA",
        "NOME UTENTE",
        "RUOLO",
        "DATA FATTURA",
        "STOP TOTALE",
        "TOTALE FATTURA",
        "COSTO TOTALE DEL SERVIZIO",
        "VAT",
        "IMPORTO TOTALE",
        "IMPORTO TOTALE DELLE RICARICHE",
        "IMPORTO TOTALE DELLA COMMISSIONE",
      ],
    ];

    const tableData = data.map((elt) => [
      elt.courtesyReceipt,
      elt.username ? elt.username : elt.companyName,
      elt.role,
      moment(elt.creationDate).format("DD/MM/YYYY HH:mm:ss"),
      // moment(elt.todate).format("DD/MM/YYYY HH:mm:ss"),
      elt.totalStops,
      `${
        elt.role === "DEALER"
          ? `€${ReplaceFunction(
              elt.totalRefillAmount?.toFixed(2) - elt.totalCommision?.toFixed(2)
            )}`
          : `€${ReplaceFunction(elt.totalParkingCharge?.toFixed(2))}`
      }`,
      `€${ReplaceFunction((elt.totalServiceFee / 1.22)?.toFixed(2))}`,
      `€${ReplaceFunction(
        (elt.totalServiceFee - elt.totalServiceFee / 1.22)?.toFixed(2)
      )}`,
      // `€${ReplaceFunction((elt.totalServiceFee * 0.22)?.toFixed(2))}`,
      `${
        elt.role === "DEALER"
          ? `€${ReplaceFunction(
              elt.totalRefillAmount?.toFixed(2) - elt.totalCommision?.toFixed(2)
            )}`
          : `€${ReplaceFunction(elt.totalParkingCharge + elt.totalServiceFee)}`

        // : `€${ReplaceFunction(
        //   elt.totalParkingCharge +
        //     elt.totalServiceFee +
        //     elt.totalServiceFee * 0.22
        // )}`
      }`,
      `€${ReplaceFunction(elt.totalRefillAmount?.toFixed(2))}`,
      `€${ReplaceFunction(elt.totalCommision?.toFixed(2))}`,
    ]);

    tableData.push([
      "",
      "",
      "",
      {
        content: "TOTALE:",
        colSpan: 1,
        styles: { halign: "center", fontStyle: "bold" },
      },
      {
        content: totalStop,
        colSpan: 1,
        styles: { halign: "left", fontStyle: "bold" },
      },
      {
        content: `€${ReplaceFunction(totalCost?.toFixed(2))}`,
        colSpan: 1,
        styles: { halign: "left", fontStyle: "bold" },
      },
    ]);

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 100,
        head: headers,
        body: tableData,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    setIsDownloadLoading("");
    doc.save("Invoices.pdf");
  };

  const handleDownloadExcel = async () => {
    setIsDownloadLoading("excel");
    const response = await invoiceService.getAdminInvoice({
      fromDate: new Date(startDate).getTime(),
      role: selectedRole,
      searchText: searchText === undefined ? "" : searchText,
      toDate: new Date(endDate).getTime(),
    });
    const data = response.data.apiresponse.data.invoices;

    let totalCost = 0;
    let totalStop = 0;
    for (let i = 0; i < data.length; i++) {
      totalCost +=
        data[i].role === "DEALER"
          ? parseFloat(data[i].totalRefillAmount - data[i].totalCommision)
          : parseFloat(data[i].totalParkingCharge);
      // totalCost += parseFloat(data[i].totalParkingCharge);
      totalStop += parseFloat(data[i].totalStops);
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      alignment: { horizontal: "center" },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["FATTURE"]);
    worksheet.mergeCells("A1:J1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    titleRow.getCell(1).font = commonCellStyle.font;
    worksheet.addRow([]);
    worksheet.mergeCells("A2:F2");

    worksheet.addRow([]);
    worksheet.mergeCells("A3:F3");

    worksheet.addRow([]);
    worksheet.mergeCells("A4:F4");

    // Add column headers
    const headers = [
      "RICEVUTA DI CORTESIA",
      "NOME UTENTE",
      "RUOLO",
      "DATA FATTURA",
      "STOP TOTALE",
      "TOTALE FATTURA",
      "COSTO TOTALE DEL SERVIZIO",
      "VAT",
      "IMPORTO TOTALE",
      "IMPORTO TOTALE DELLE RICARICHE",
      "IMPORTO TOTALE DELLA COMMISSIONE",
    ];
    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 22;
    // worksheet.getColumn(5).width = 22;
    worksheet.getColumn(5).width = 18;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 40;
    worksheet.getColumn(11).width = 40;
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.font = commonCellStyle.font;
      cell.alignment = commonCellStyle.alignment;
    });

    // Add data rows
    data.forEach((elt) => {
      const table = worksheet.addRow([
        elt.courtesyReceipt,
        elt.username ? elt.username : elt.companyName,
        elt.role,
        moment(elt.creationDate).format("DD/MM/YYYY HH:mm:ss"),
        elt.totalStops,
        // `€${ReplaceFunction(elt.totalParkingCharge?.toFixed(2))}`,
        `${
          elt.role === "DEALER"
            ? `€${ReplaceFunction(
                elt.totalRefillAmount?.toFixed(2) -
                  elt.totalCommision?.toFixed(2)
              )}`
            : // ReplaceFunction(elt.totalCommisionAmount?.toFixed(2))
              // elt.totalParkingCharge?.toFixed(2)
              `€${ReplaceFunction(elt.totalParkingCharge?.toFixed(2))}`
        }`,
        // elt.totalParkingCharge,
        `€${ReplaceFunction((elt.totalServiceFee / 1.22)?.toFixed(2))}`,
        `€${ReplaceFunction(
          (elt.totalServiceFee - elt.totalServiceFee / 1.22)?.toFixed(2)
        )}`,
        // `€${ReplaceFunction((elt.totalServiceFee * 0.22)?.toFixed(2))}`,
        `${
          elt.role === "DEALER"
            ? `€${ReplaceFunction(
                elt.totalRefillAmount?.toFixed(2) -
                  elt.totalCommision?.toFixed(2)
              )}`
            : // ReplaceFunction(elt.totalCommisionAmount?.toFixed(2))
              `€${ReplaceFunction(
                elt.totalParkingCharge + elt.totalServiceFee
              )}`
        }`,
        `€${ReplaceFunction(elt.totalRefillAmount?.toFixed(2))}`,
        `€${ReplaceFunction(elt.totalCommision?.toFixed(2))}`,
      ]);
      table.eachCell((cell) => {
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
        cell.alignment = commonCellStyle.alignment;
      });
    });

    const totalRicaricheRow = worksheet.addRow([
      "",
      "",
      "",
      "TOTALE:",
      `${totalStop}`,
      `€${ReplaceFunction(totalCost?.toFixed(2))}`,
    ]);
    totalRicaricheRow.getCell(4).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(5).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(6).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(4).font = commonCellStyle.font;
    totalRicaricheRow.getCell(5).font = commonCellStyle.font;
    totalRicaricheRow.getCell(6).font = commonCellStyle.font;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    setIsDownloadLoading("");
    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Invoices.xlsx`;
    link.click();
  };

  useEffect(() => {
    setSearchText(searchingName);
  }, [searchingName]);

  const exportZIP = async () => {
    if (startDate && endDate) {
      const from = moment(startDate).valueOf();
      const to = moment(endDate).valueOf();

      const url = `${process.env.REACT_APP_BASE_URL}/invoice/export/xmlZip?fromDate=${from}&toDate=${to}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: neosStorage.token,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "invoices.zip";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);

        // const link = document.createElement("a");
        // link.href = url;
        // link.download = "downloaded-file";
        // link.target = "_blank";
        // document.body.appendChild(link);

        // link.click();

        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log("error ", error);
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    } else {
      enqueueSnackbar("Please Select Time period to Download ZIP", {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">{t("invoices")}</h2>
      <Card>
        {openPDF && (
          <PreviewPDF
            newRole={newRole}
            show={openPDF}
            onHide={() => setOpenPDF(false)}
            PageWithNumber={PageWithNumber}
            userDetails={userDetails}
            invoiceDetail={invoiceDetail}
            adminDetails={adminDetails}
          />
        )}

        <table ref={tableRef} style={{ display: "none" }}>
          <tbody>
            <tr>
              <th>Username</th>
              <th>Role</th>
              <th>Invoice Creation Date</th>
              <th>Total Cost</th>
              <th>Total Stop</th>
            </tr>
            <tr>
              <td>Marco</td>
              <td>Business User</td>
              <td>14/07/2023</td>
              <td>50</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Leonardo</td>
              <td>User</td>
              <td>23/06/2023</td>
              <td>30</td>
              <td>5</td>
            </tr>
            <tr>
              <td>Matteo</td>
              <td>Dealer</td>
              <td>30/06/2023</td>
              <td>70</td>
              <td>2</td>
            </tr>
          </tbody>
        </table>

        <div className="p-3">
          <Row className="mt-2 mb-1">
            <Col md={2} className="mb-3">
              <Multiselect
                classNamePrefix="Select2"
                options={FILTERROLE}
                singleSelect
                placeholder={t("role")}
                displayValue="key"
                name="role"
                onChange={(selectedOption) =>
                  setSelectedRole(selectedOption.value)
                }
                styles={CUSTOMDROPDOWNSTYLE}
              />
            </Col>
            <Col md={2}>
              <DateRangePicker
                initialSettings={{
                  startDate: moment(new Date()).format("DD/MM/YYYY"),
                  endDate: moment(new Date()).format("DD/MM/YYYY"),
                  ranges: RANGES,
                  autoUpdateInput: false,
                  locale: {
                    format: "DD/MM/YYYY", // Specify the desired format
                  },
                }}
                onApply={setDates}
                onCancel={cancelDate}
                onHide={cancelDate}
              >
                {startDate && endDate ? (
                  <input
                    type="text"
                    placeholder={t("selectperiodvalidity")}
                    value={
                      startDate && endDate
                        ? `${moment(startDate).format("DD/MM/YYYY")} - ${moment(
                            endDate
                          ).format("DD/MM/YYYY")}`
                        : ""
                    }
                    defaultValue=""
                    onChange={(startDate, endDate) =>
                      handleDateChange(startDate, endDate)
                    }
                    className="form-control"
                  />
                ) : (
                  <input
                    type="text"
                    placeholder={t("selectperiodvalidity")}
                    defaultValue=""
                    className="form-control"
                  />
                )}
              </DateRangePicker>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <div className="d-flex flex-grow-1 flex-md-grow-0 flex-lg-grow-0 align-items-center md-form mt-2 mt-md-auto mt-lg-auto ms-auto">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-md py-3"
                      value={searchText}
                      onChange={handlesearch}
                      placeholder={t("search") + "..."}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <button
                  className="btn btn-primary btn-sm me-2 p-2 ms-3 d-flex justify-content-center align-items-center"
                  style={{ width: "70px" }}
                  onClick={handleDownloadExcel}
                >
                  {isDownloadLoading === "excel" ? (
                    <CircularProgress
                      style={{ color: "#fff", width: "16px", height: "16px" }}
                    />
                  ) : (
                    <span>
                      <i class="fa fa-download"></i> {t("excel")}
                    </span>
                  )}
                </button>
                {/* </DownloadTableExcel> */}

                <button
                  className="btn btn-primary btn-sm me-2 p-2 d-flex justify-content-center align-items-center"
                  style={{ width: "60px" }}
                  onClick={exportPDF}
                >
                  {isDownloadLoading === "pdf" ? (
                    <CircularProgress
                      style={{ color: "#fff", width: "16px", height: "16px" }}
                    />
                  ) : (
                    <span>
                      <i class="fas fa-file-pdf"></i> PDF
                    </span>
                  )}
                </button>

                <button
                  className="btn btn-primary btn-sm me-1 p-2"
                  onClick={exportZIP}
                >
                  <i class="fas fa-file-pdf"></i> ZIP
                </button>
              </div>
            </Col>
          </Row>
          <DataTable
            title="Invoices"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={CUSTOM_STYLES}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(Invoices);
