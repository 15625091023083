import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const ReportService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const get1stReport = async (cityName, endDate, startDate) => {
    const response = await serverCall.customAxios.get(
      `/report/?cityName=${cityName}&endDate=${endDate}&startDate=${startDate}`,
      { headers }
    );
    return response;
  };
  const get2ndReport = async (areaName, cityName, endDate, startDate) => {
    if (areaName) {
      const response = await serverCall.customAxios.get(
        `/report/details?areaName=${areaName}&cityName=${cityName}&endDate=${endDate}&startDate=${startDate}`,
        { headers }
      );
      return response;
    } else {
      const response = await serverCall.customAxios.get(
        `/report/details?cityName=${cityName}&endDate=${endDate}&startDate=${startDate}`,
        { headers }
      );
      return response;
    }
  };
  const get3rdReport = async (cityName, endDate, startDate) => {
    const response = await serverCall.customAxios.get(
      `/report/street?cityName=${cityName}&endDate=${endDate}&startDate=${startDate}`,
      { headers }
    );
    return response;
  };
  const get4thReport = async (cityName, endDate, startDate) => {
    const response = await serverCall.customAxios.get(
      `/report/city?cityName=${cityName}&endDate=${endDate}&startDate=${startDate}`,
      { headers }
    );
    return response;
  };
  const get5thReport = async (cityName, endDate, startDate) => {
    const response = await serverCall.customAxios.get(
      `/report/dealer?cityName=${cityName}&endDate=${endDate}&startDate=${startDate}`,
      { headers }
    );
    return response;
  };
  const get6thReport = async (endDate, startDate) => {
    const response = await serverCall.customAxios.get(
      `/report/servicefee?endDate=${endDate}&startDate=${startDate}`,
      { headers }
    );
    return response;
  };

  return {
    get1stReport,
    get2ndReport,
    get3rdReport,
    get4thReport,
    get5thReport,
    get6thReport,
  };
};

export default ReportService;
