import moment from "moment";
import React from "react";
import ExcelJS from "exceljs";
import { ReplaceFunction } from "../../utils/constants";

const TotalParkingSpacesExcel = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalAmount = 0;
  let totalEvent = 0;
  for (let i = 0; i < data?.length; i++) {
    totalAmount += parseFloat(data[i]?.sumOfCharge);
    totalEvent += parseFloat(data[i]?.totalEvent);
  }

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      alignment: { horizontal: "center" },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["Parcheggi per Zona - APP"]);
    worksheet.mergeCells("A1:C1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    titleRow.getCell(1).font = commonCellStyle.font;
    worksheet.addRow([]);
    worksheet.mergeCells("A2:F2");

    worksheet.addRow([]);

    worksheet.addRow([`Città:    ${selectedCity.label}`]);
    worksheet.addRow([`Data Inizio:    ${start}`]);
    worksheet.addRow([`Data Fine:    ${end}`]);

    worksheet.addRow([]);

    // Add column headers
    const headers = ["Descrizione Zona", "N° Tot. Parcheggi", "Tot. Importo"];
    worksheet.getColumn(1).width = 80;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell) => {
      cell.font = commonCellStyle.font;
      cell.border = commonCellStyle.border;
    });

    headerRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    headerRow.getCell(2).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    headerRow.getCell(3).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add data rows
    data.forEach((elt) => {
      const table = worksheet.addRow([
        elt.areaname,
        elt.totalEvent,
        "€" + ReplaceFunction(elt.sumOfCharge?.toFixed(2)),
      ]);
      table.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      table.getCell(2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      table.getCell(3).alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      table.eachCell((cell) => {
        cell.border = commonCellStyle.border;
      });
    });

    worksheet.addRow([]);

    const totalRicaricheRow = worksheet.addRow([
      "Totale periodo",
      totalEvent,
      "€ " + ReplaceFunction(totalAmount?.toFixed(2)),
    ]);
    totalRicaricheRow.eachCell((cell) => {
      cell.border = commonCellStyle.border;
      cell.font = { bold: true };
    });
    totalRicaricheRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    totalRicaricheRow.getCell(2).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    totalRicaricheRow.getCell(3).alignment = {
      vertical: "middle",
      horizontal: "right",
    };

    worksheet.addRow([]);
    worksheet.addRow([]);

    const footerRow1 = worksheet.addRow([
      `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`,
    ]);
    footerRow1.getCell(1).font = { size: 10 };
    worksheet.mergeCells(`A${footerRow1.number}:C${footerRow1.number}`);

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Totale_parcheggi_per_zona_${selectedCity.label}_${moment(
      startDate,
      "DD/MM/YYYY"
    ).format("DDMMYYYYHHmm")}.xlsx`;
    link.click();
  };

  return downloadExcel;
};

export default TotalParkingSpacesExcel;
