import React from "react";
import ExcelJS from "exceljs";
import moment from "moment";
import { ReplaceFunction } from "../../utils/constants";

const DealerRechargesByCityExcel = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalAmount = 0;
  let totalNumberOfRecharge = 0;
  let totalNumberOfRechargeUser = 0;
  for (let i = 0; i < data?.length; i++) {
    totalAmount += parseFloat(data[i]?.totalAmount);
    totalNumberOfRecharge += parseFloat(data[i]?.totalTransaction);
    totalNumberOfRechargeUser += parseFloat(data[i]?.totalUser);
  }

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Ricariche APP per Città e POS");

    // Styles

    const subtitleStyle = {
      font: { size: 12, bold: true },
      alignment: { horizontal: "left" },
    };
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "middle" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
    };

    // Add title
    const titleCell = worksheet.addRow(["Ricariche APP per Città e POS"]);
    worksheet.mergeCells("A1:D1");
    titleCell.font = { size: 14, bold: true };
    titleCell.height = 20;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };

    worksheet.addRow([]);
    worksheet.mergeCells("A2:D2");

    // Add city and period
    const cityCell = worksheet.addRow([`Città:   ${selectedCity.label}`]);
    worksheet.mergeCells("A3:D3");
    cityCell.font = { size: 12, bold: true };
    cityCell.alignment = { horizontal: "left" };

    worksheet.mergeCells("A4:D4");
    const periodCell = worksheet.getCell("A4");
    periodCell.value = `Periodo dal    ${start}    al    ${end}`;
    periodCell.style = subtitleStyle;

    worksheet.addRow([]);
    worksheet.mergeCells("A5:D5");

    // Add headers
    const headers = ["Punto", "n. ricariche", "n. card", "Importo"];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });

    // Add data
    data.forEach((item) => {
      const row = worksheet.addRow([
        item.companyName
          ? item.companyName
          : item.userName && item.surName
          ? `${item.userName} ${item.surName}`
          : item.userName
          ? item.userName
          : item.surName
          ? item.surName
          : "--",
        item.totalTransaction,
        item.totalUser,
        "€ " + ReplaceFunction(item.totalAmount?.toFixed(2)),
      ]);

      row.getCell(1).alignment = { horizontal: "left" };
      row.getCell(2).alignment = { horizontal: "center" };
      row.getCell(3).alignment = { horizontal: "center" };
      row.getCell(4).alignment = { horizontal: "right" };
      row.getCell(4).numFmt = '"€"#,##0.00';
    });

    // Add totals
    const totalRow = worksheet.addRow([
      "TOTALE",
      totalNumberOfRecharge,
      totalNumberOfRechargeUser,
      "€ " + ReplaceFunction(totalAmount?.toFixed(2)),
    ]);
    totalRow.eachCell((cell) => {
      cell.style = {
        font: { bold: true },
        border: { top: { style: "thin" } },
      };
    });
    totalRow.getCell(1).alignment = { horizontal: "left" };
    totalRow.getCell(2).alignment = { horizontal: "center" };
    totalRow.getCell(3).alignment = { horizontal: "center" };
    totalRow.getCell(4).alignment = { horizontal: "right" };
    totalRow.getCell(4).numFmt = '"€"#,##0.00';

    // Set column widths
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;

    worksheet.addRow([]);

    // Add footer
    const footerRow = worksheet.addRow([
      `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`,
    ]);
    worksheet.mergeCells(`A${worksheet.rowCount}:D${worksheet.rowCount}`);
    footerRow.getCell(1).font = { size: 10 };

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Ricariche_Dealer_per_città_${selectedCity.label}_${moment(
      startDate,
      "DD/MM/YYYY"
    ).format("DDMMYYYYHHmm")}.xlsx`;
    link.click();
  };

  return downloadExcel;
};

export default DealerRechargesByCityExcel;
