import jsPDF from "jspdf";
import {
  ReplaceFunction,
  ReplaceFunctionWithoutDecimal,
} from "../../utils/constants";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";

const SummaryByCity = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalEvent = 0;
  let totalTime = 0;
  let averageTime = 0;
  let totalNoVatSumOfCharge = 0;
  let totalVatSumofCharge = 0;
  let totalSumOfCharge = 0;
  let totalNoVatSumOfServiceFee = 0;
  let totalVatSumofServiceFee = 0;
  let totalSumOfServiceFee = 0;

  for (let i = 0; i < data?.length; i++) {
    totalEvent += parseFloat(data[i]?.totalEvent);
    totalTime += Number(data[i]?.totalTime / 60000);
    averageTime +=
      Number(data[i].totalTime / 60000) / Number(data[i]?.totalEvent);
    totalNoVatSumOfCharge += Number(data[i]?.sumOfCharge / 1.22);
    totalVatSumofCharge +=
      Number(data[i]?.sumOfCharge) - Number(data[i]?.sumOfCharge / 1.22);
    totalSumOfCharge += Number(data[i]?.sumOfCharge);

    //   totalNoVatSumOfCharge += data[i]?.sumOfCharge;
    // totalVatSumofCharge += (data[i]?.sumOfCharge * 22) / 100;
    // totalSumOfCharge +=
    //   Number(data[i].sumOfCharge) +
    //   Number((data[i].sumOfServicefee * 22) / 100);

    totalNoVatSumOfServiceFee += Number(data[i]?.sumOfServicefee / 1.22);
    totalVatSumofServiceFee +=
      Number(data[i]?.sumOfServicefee) -
      Number(data[i]?.sumOfServicefee / 1.22);
    totalSumOfServiceFee += Number(data[i]?.sumOfServicefee);

    // totalNoVatSumOfServiceFee += data[i]?.sumOfServicefee;
    // totalVatSumofServiceFee += (data[i]?.sumOfServicefee * 22) / 100;
    // totalSumOfServiceFee +=
    //   Number(data[i].sumOfServicefee) +
    //   Number((data[i].sumOfServicefee * 22) / 100);
  }

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3, or A4
    const orientation = "landscape"; // portrait or landscape
    const titleFontSize = 16;
    const textColor = [0, 0, 0];

    const doc = new jsPDF(orientation, unit, size);

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const footer1 = `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`;
        doc.setFontSize(12);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(footer1, 40, doc.internal.pageSize.height - 20);

        const footer2 = `Page ${i} of ${pageCount}`;
        doc.setFontSize(12);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(
          footer2,
          doc.internal.pageSize.width - 100,
          doc.internal.pageSize.height - 20
        );
      }
    };

    // (ReplaceFunction(elt.sumOfCharge.toFixed(2)) * 22) / 100

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 980, 20, 160, 60);

    const title = `Rendicontazione per: ${selectedCity.label}        Periodo:  ${start} - ${end}`;
    doc.setFontSize(titleFontSize);
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "normal");
    doc.text(title, 40, 80);

    const headers = [
      [
        "Data",
        "N. di soste",
        "Tempo totale di parcheggio in minuti",
        "Tempo medio di parcheggio in minuti",
        "Importo totale degli eventi di parcheggio senza IVA 22%",
        "Totale IVA 22% dell'importo totale del parcheggio",
        "Importo totale del parcheggio",
        "Importo totale del servizio senza IVA 22%",
        "Totale IVA 22% dell'importo totale del servizio",
        "Importo totale della commissione di servizio",
        "Importo totale del parcheggio",
      ],
    ];

    // set data table columns names
    const newData = data.map((elt) => [
      {
        content: moment(elt.date, "DD/MM/YYYY").format("DD/MM/YY"),
        styles: {
          halign: "center",
        },
      },
      {
        content: ReplaceFunctionWithoutDecimal(elt.totalEvent),
        styles: {
          halign: "center",
        },
      },
      {
        content: ReplaceFunctionWithoutDecimal(
          Math.round(elt.totalTime / 60000)
        ),
        styles: {
          halign: "center",
        },
      },
      {
        content: ReplaceFunctionWithoutDecimal(
          Math.round(elt.totalTime / 60000 / elt.totalEvent)
        ),
        styles: {
          halign: "center",
        },
      },
      {
        content: ReplaceFunction((elt.sumOfCharge / 1.22).toFixed(2)) + " €",
        // content: ReplaceFunction(elt.sumOfCharge.toFixed(2)) + " €",
        styles: {
          fontStyle: "bold",
          halign: "center",
        },
      },
      {
        content:
          ReplaceFunction(
            elt.sumOfCharge.toFixed(2) - (elt.sumOfCharge / 1.22).toFixed(2)
          ) + " €",
        // ReplaceFunction(((elt.sumOfCharge * 22) / 100).toFixed(2)) + " €",
        styles: {
          fontStyle: "bold",
          halign: "center",
        },
      },
      {
        content: ReplaceFunction(elt.sumOfCharge.toFixed(2)) + " €",
        // ReplaceFunction(
        //   (
        //     Number(elt.sumOfCharge) + Number((elt.sumOfCharge * 22) / 100)
        //   ).toFixed(2)
        // ) + " €",
        styles: {
          halign: "center",
        },
      },
      {
        content:
          ReplaceFunction((elt.sumOfServicefee / 1.22).toFixed(2)) + " €",
        // content: ReplaceFunction(elt.sumOfServicefee.toFixed(2)) + " €",
        styles: { fontStyle: "bold", halign: "center" },
      },
      {
        content:
          ReplaceFunction(
            elt.sumOfServicefee.toFixed(2) -
              (elt.sumOfServicefee / 1.22).toFixed(2)
          ) + " €",
        // ReplaceFunction(((elt.sumOfServicefee * 22) / 100).toFixed(2)) + " €",
        styles: { fontStyle: "bold", halign: "center" },
      },
      {
        content: ReplaceFunction(elt.sumOfServicefee.toFixed(2)) + " €",

        // ReplaceFunction(
        //   (
        //     Number(elt.sumOfServicefee) +
        //     Number((elt.sumOfServicefee * 22) / 100)
        //   ).toFixed(2)
        // ) + " €",
        styles: {
          halign: "center",
        },
      },
      {
        content: ReplaceFunction(elt.sumOfCharge.toFixed(2)) + " €",
        styles: {
          halign: "center",
        },
      },
    ]);

    newData.push([
      {
        content: `Totale`,
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 0, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunctionWithoutDecimal(Math.round(totalEvent)),
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunctionWithoutDecimal(Math.round(totalTime)),
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunctionWithoutDecimal(
          Math.round(totalTime / totalEvent)
        ),
        // content: ReplaceFunctionWithoutDecimal(Math.round(averageTime)),
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalNoVatSumOfCharge) + " €",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalVatSumofCharge) + " €",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalSumOfCharge) + " €",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalNoVatSumOfServiceFee) + " €",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalVatSumofServiceFee) + " €",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalSumOfServiceFee) + " €",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalSumOfCharge) + " €",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          // lineWidth: { top: 1, right: 1, bottom: 1, left: 1 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
    ]);

    const headStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      fontStyle: "bold",
      fontSize: 12,
      lineColor: [...textColor],
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 12,
      lineColor: [...textColor],
    };

    const alternateRowStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 110,
        head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    addFooter(doc);

    doc.save(
      `Riepilogo_per_città_${selectedCity.label}_${moment(
        startDate,
        "DD/MM/YYYY"
      ).format("DDMMYYYYHHmm")}.pdf`
    );
  };

  return exportPDF;
};

export default SummaryByCity;
