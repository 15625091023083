import jsPDF from "jspdf";
import { ReplaceFunction } from "../../utils/constants";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";

const DealerRechargesByCity = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalAmount = 0;
  let totalNumberOfRecharge = 0;
  let totalNumberOfRechargeUser = 0;
  for (let i = 0; i < data?.length; i++) {
    totalAmount += parseFloat(data[i]?.totalAmount);
    totalNumberOfRecharge += parseFloat(data[i]?.totalTransaction);
    totalNumberOfRechargeUser += parseFloat(data[i]?.totalUser);
  }

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape
    const titleFontSize = 18;
    const textColor = [0, 0, 0];

    const doc = new jsPDF(orientation, unit, size);

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const footer1 = `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`;
        doc.setFontSize(10);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(footer1, 40, doc.internal.pageSize.height - 20);

        const footer2 = `Page ${i} of ${pageCount}`;
        doc.setFontSize(10);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(
          footer2,
          doc.internal.pageSize.width - 100,
          doc.internal.pageSize.height - 20
        );
      }
    };

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 590, 20, 160, 60);

    const title = "Ricariche APP per Città e POS";
    doc.setFontSize(titleFontSize);
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "normal");
    doc.text(title, 40, 35);

    const city = "Città:";
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(city, 40, 110);

    const cityName = selectedCity.label;
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(cityName, 120, 110);

    const period = `Periodo dal   ${start}   al   ${end}`;
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.text(period, 40, 140);

    const headers = [["Punto", "n. ricariche", "n. card", "Importo"]];

    // set data table columns names
    const newData = data.map((elt) => [
      {
        content: `${
          elt.companyName
            ? elt.companyName
            : elt.userName && elt.surName
            ? elt.userName + " " + elt.surName
            : elt.userName
            ? elt.userName
            : elt.surName
            ? elt.surName
            : "--"
        }`,
        styles: {
          halign: "left",
        },
      },
      {
        content: elt.totalTransaction,
        styles: {
          halign: "center",
        },
      },
      {
        content: elt.totalUser,
        styles: {
          halign: "center",
        },
      },
      {
        content: "€ " + ReplaceFunction(elt.totalAmount?.toFixed(2)),
        styles: {
          halign: "right",
        },
      },
    ]);

    newData.push([
      {
        content: `TOTALE`,
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: totalNumberOfRecharge,
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: totalNumberOfRechargeUser,
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: "€ " + ReplaceFunction(totalAmount?.toFixed(2)),
        colSpan: 1,
        styles: {
          halign: "right",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
    ]);

    const headStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      fontStyle: "bold",
      fontSize: 12,
      lineColor: [...textColor],
      lineWidth: { bottom: 1 },
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 12,
      lineColor: [...textColor],
    };

    const alternateRowStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
    };

    const columnStyles = {
      0: { cellWidth: 450 },
      1: { cellWidth: 100 },
      2: { cellWidth: 100 },
      3: { cellWidth: 100, halign: "right" },
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 160,
        head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    addFooter(doc);

    doc.save(
      `Ricariche_Dealer_per_città_${selectedCity.label}_${moment(
        startDate,
        "DD/MM/YYYY"
      ).format("DDMMYYYYHHmm")}.pdf`
    );
  };

  return exportPDF;
};

export default DealerRechargesByCity;
