import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import Multiselect from "react-select";
import * as yup from "yup";
import { ANCHORORIGIN, FORMIKERROR, SUCCESS } from "../../utils/constants";
import Enums from "../../utils/enums";
import UserService from "../../services/user.service";
import withLoader from "../../layout/Loader/WithLoader";
import CityService from "../../services/city.service";
import useMenuState from "../../hooks/useMenuState";
import { useTranslation } from "react-i18next";

const FreeDealerProfile = () => {
  const userService = UserService();
  const cityService = CityService();
  const { t } = useTranslation("common");
  const { PREFERRED_PAYMENT, DEALER_TYPE } = Enums();
  const { id } = useParams();
  const [data, setData] = useState("");
  const [newCity, setNewCity] = useState([]);
  const [vatNumber, setVatNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [
    openPreferredPayment,
    handleOpenPreferredPayment,
    handleClosePreferredPayment,
  ] = useMenuState(false);
  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(false);
  const [openDealerType, handleOpenDealerType, handleCloseDealerType] =
    useMenuState(false);

  // get all city names
  const getAllCity = async () => {
    const response = await cityService.getAllPageCity();
    setNewCity(response.data.apiresponse.data);
  };

  // set city names in dropdown
  const city = [
    ...newCity.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  ];

  // Set user details in fields
  const UserDetails = async () => {
    const response = await userService.getUserById(id);
    const result = response.data.apiresponse.data;
    setData(response.data.apiresponse.data);

    formik.setFieldValue("id", result.id);
    formik.setFieldValue("username", result.username);
    formik.setFieldValue("email", result.email);
    formik.setFieldValue("surname", result.surname);
    formik.setFieldValue("region", result.region);
    formik.setFieldValue("address", result.address);
    formik.setFieldValue("cap", result.cap);
    formik.setFieldValue("percentageOfEarning", result.earning);
    formik.setFieldValue("einvoicing", result.einvoicing);
    formik.setFieldValue("phoneNo", result.phoneNo);
    formik.setFieldValue("preferredPaymentType", result.preferredPaymentType);
    formik.setFieldValue("province", result.province);
    formik.setFieldValue("taxCode", result.taxCode);
    formik.setFieldValue("town", result.town);
    formik.setFieldValue("vatNumber", result.vat);
    formik.setFieldValue("walletAmount", result.walletAmount);
    formik.setFieldValue("dealerType", result.dealerType);
    formik.setFieldValue("rechargeLimit", result.rechargeLimit);
    formik.setFieldValue("profile", result.profile);
    formik.setFieldValue("companyName", result.companyName);
    setVatNumber(result.vat);
    setSelectedImage(result.profile);
  };

  useEffect(() => {
    UserDetails();
    getAllCity();
  }, []);

  const [update, setUpdate] = useState(true);

  const edit = () => {
    setUpdate(!update);
  };

  const formik = useFormik({
    initialValues: {
      address: "",
      cap: "",
      childUserPassword: "",
      countryCode: "",
      dealerType: null,
      einvoicing: "",
      email: "",
      parentUserId: 0,
      percentageOfEarning: 0,
      phoneNo: "",
      preferredPaymentType: null,
      province: "",
      region: "",
      role: "DEALER",
      surname: "",
      taxCode: "",
      town: "",
      cityIds: [],
      username: "",
      vatNumber: "",
      companyName: "",
      profile: "",
    },
    validationSchema: yup.object().shape({
      username: yup.string().required("Please Enter User Name Number!"),
      surname: yup.string().required("Please Enter Surname"),
      email: yup.string().email().required("Please Enter Email"),
      role: yup.string().required("Please Enter Role!"),

      taxCode:
        data.role === "DEALER"
          ? yup
              .string()
              .test(
                "valid-tax-code",
                "Invalid tax code Example : RRSMRA74D22A001Q",
                (value) => {
                  if (!value) return true;
                  const taxCodeRegex =
                    /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
                  return taxCodeRegex.test(value);
                }
              )
          : yup.string(),

      address:
        data.role === "DEALER"
          ? yup.string().required("PLease Enter Street Address")
          : yup.string(),
      town:
        data.role === "DEALER"
          ? yup.mixed().required("Please Enter City")
          : yup.mixed(),
      province:
        data.role === "DEALER"
          ? yup.mixed().required("Please Enter State")
          : yup.mixed(),
      cap:
        data.role === "DEALER"
          ? yup
              .string()
              .max(5, "Maximum 5 numbers!")
              .min(5, "Minimum 5 numbers!")
              .required("Please Enter CAP!")
          : yup.mixed(),

      einvoicing: yup
        .string()
        .matches(
          /^[A-Z0-9]+$/,
          "It contains only UPPERCASE letters and Numbers"
        )
        .max(7, "Must be at most 7 characters"),

      vatNumber: yup
        .string()
        .matches(/^\d{11}$/, "Please enter exactly 11 digits"),
    }),
    onSubmit: async (values) => {
      const {
        id,
        username,
        email,
        childUserPassword,
        province,
        role,
        parentUserId,
        address,
        cap,
        countryCode,
        dealerType,
        einvoicing,
        percentageOfEarning,
        phoneNo,
        preferredPaymentType,
        region,
        surname,
        taxCode,
        town,
        cityIds,
        vatNumber,
        companyName,
        profile,
      } = values;
      const response = await userService.updateUser(id, {
        username,
        email,
        cityIds,
        town,
        role,
        parentUserId,
        childUserPassword,
        address,
        cap,
        countryCode,
        dealerType,
        einvoicing,
        percentageOfEarning,
        phoneNo,
        preferredPaymentType,
        region,
        surname,
        taxCode,
        province,
        vatNumber,
        companyName,
      });

      const editdata = new FormData();
      editdata.append("profile", profile);
      editdata.append("userId", id);

      if (changeImage) {
        const profileImage = await userService.addProfile(editdata);
      }

      setUpdate(!update);
      enqueueSnackbar(t("DEALER_PROFILE_UPDATE_SUCCESSFULLY"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
    },
  });

  const handleCAPChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");

    formik.setFieldValue("cap", sanitizedInput);
  };

  const handlePercentage = (e) => {
    const value = e.target.value;
    const sanitizedInput = value.replace(/[^\d+]/g, "");
    if (sanitizedInput.length > 4) {
      return;
    }

    let formattedNumber = "";
    for (let i = 0; i < sanitizedInput.length; i++) {
      if (i === 2) {
        formattedNumber += `.${sanitizedInput.charAt(i)}`;
      } else {
        formattedNumber += sanitizedInput.charAt(i);
      }
    }
    formik.setFieldValue("percentageOfEarning", formattedNumber);
  };

  // Select city from dropdown
  const selectCity = async (selectedOption) => {
    const id = selectedOption.value;
    formik.setFieldValue("town", selectedOption.label);

    const response = await cityService.getCity(id);

    formik.setFieldValue("province", response.data.apiresponse.data.state);
  };

  const handlefileChange = (e) => {
    setChangeImage(true);
    const selectedFile = e.currentTarget.files[0];
    formik.setFieldValue("profile", selectedFile);

    // setSelectedImage([selectedFile]);
    const imageUrl = URL.createObjectURL(selectedFile);
    setSelectedImage(imageUrl);
  };

  const handleVAT = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    setVatNumber(sanitizedInput);
    formik.setFieldValue("vatNumber", sanitizedInput);
  };

  const handleInvoiceCode = (e) => {
    const uppercase = e.target.value.toUpperCase();
    if (uppercase.length > 7) {
      return;
    }
    formik.setFieldValue("einvoicing", uppercase);
  };

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">
          {data.username} {t("profile")}
        </h2>{" "}
        <form onSubmit={formik.handleSubmit}>
          {update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="button"
              onClick={edit}
            >
              <i className="fe fe-edit me-1"></i> {t("edit")}
            </button>
          )}
          {!update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="submit"
            >
              <i className="fas fa-save me-1"></i> {t("save")}
            </button>
          )}
          <Card className="custom-card">
            <Card.Body>
              <div className="row">
                <Col
                  xl={2}
                  lg={12}
                  className="d-flex align-items-center text-center justify-content-center"
                >
                  <div>
                    {update && (
                      <div className="mb-2">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt=""
                            width={"300px"}
                            className="mb-2"
                          />
                        ) : (
                          <div className="text-danger">
                            Oops! Image Not Found
                          </div>
                        )}
                      </div>
                    )}
                    {!update && (
                      <div
                        style={{ width: "auto" }}
                        className="d-flex justify-content-center mt-4 mb-3"
                      >
                        <div>
                          {selectedImage ? (
                            <>
                              <input
                                type="file"
                                id="file"
                                name="profile"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  handlefileChange(e);
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  marginTop: "-30px",
                                }}
                                className="btn btn-warning btn-sm position-absolute z-index-10"
                                htmlFor="file"
                              >
                                <i class="fa fa-image"></i> Change Profile
                              </label>
                              <img
                                className="position-relative"
                                src={selectedImage}
                                alt="upload"
                                style={{
                                  objectFit: "cover",
                                  height: "150px",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <input
                                type="file"
                                id="file"
                                name="profile"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  handlefileChange(e);
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                }}
                                className="text-muted"
                                htmlFor="file"
                              >
                                <i
                                  class="fa fa-image"
                                  style={{ fontSize: "125px" }}
                                ></i>
                                Choose Image
                              </label>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={10} lg={12}>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("name")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="username"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                        {formik.errors.username && formik.touched.username ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.username}
                          </Form.Label>
                        ) : null}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("surname")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="surname"
                          value={formik.values.surname}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                        {formik.errors.surname && formik.touched.surname ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.surname}
                          </Form.Label>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("email")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.email}
                          </Form.Label>
                        ) : null}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("mobilenumber")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="phoneNo"
                          value={formik.values.phoneNo}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                        {formik.errors.phoneNo && formik.touched.phoneNo ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.phoneNo}
                          </Form.Label>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("city")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        {!update && (
                          <Multiselect
                            classNamePrefix="Select2"
                            options={city}
                            menuIsOpen={openCity}
                            onMenuOpen={handleOpenCity}
                            onMenuClose={handleCloseCity}
                            value={city.find(
                              (option) => option.label === formik.values.town
                            )}
                            singleSelect
                            placeholder="Town"
                            displayValue="key"
                            name="town"
                            onChange={selectCity}
                          />
                        )}
                        {update && (
                          <Form.Control
                            type="text"
                            name="town"
                            value={formik.values.town}
                            onChange={formik.handleChange}
                            disabled={update ? true : false}
                          />
                        )}
                        {formik.errors.town && formik.touched.town ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.town}
                          </Form.Label>
                        ) : null}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("province")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="province"
                          value={formik.values.province}
                          onChange={formik.handleChange}
                          disabled
                        />
                        {formik.errors.province && formik.touched.province ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.province}
                          </Form.Label>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("taxcode")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="taxCode"
                          value={formik.values.taxCode}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                        {formik.errors.taxCode && formik.touched.taxCode ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.taxCode}
                          </Form.Label>
                        ) : null}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("vatnumber")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="vatNumber"
                          onChange={handleVAT}
                          value={vatNumber}
                          disabled={update ? true : false}
                        />
                        {formik.errors.vatNumber && formik.touched.vatNumber ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.vatNumber}
                          </Form.Label>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("streetaddress")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                        {formik.errors.address && formik.touched.address ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.address}
                          </Form.Label>
                        ) : null}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("pincode")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="number"
                          name="cap"
                          value={formik.values.cap}
                          onChange={(e) => handleCAPChange(e)}
                          disabled={update ? true : false}
                        />
                        {formik.errors.cap && formik.touched.cap ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.cap}
                          </Form.Label>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("percentageofearnings")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="percentageOfEarning"
                          value={formik.values.percentageOfEarning}
                          onChange={handlePercentage}
                          disabled={update ? true : false}
                        />
                        {formik.errors.percentageOfEarning &&
                        formik.touched.percentageOfEarning ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.percentageOfEarning}
                          </Form.Label>
                        ) : null}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("type")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        {!update && (
                          <Multiselect
                            classNamePrefix="Select2"
                            options={DEALER_TYPE}
                            menuIsOpen={openDealerType}
                            onMenuOpen={handleOpenDealerType}
                            onMenuClose={handleCloseDealerType}
                            value={DEALER_TYPE.find(
                              (option) =>
                                option.value === formik.values.dealerType
                            )}
                            singleSelect
                            placeholder="Dealer Type"
                            displayValue="key"
                            name="dealerType"
                            onChange={selectCity}
                          />
                        )}
                        {update && (
                          <Form.Control
                            type="text"
                            name="dealerType"
                            value={formik.values.dealerType}
                            onChange={formik.handleChange}
                            disabled
                          />
                        )}
                        {formik.errors.dealerType &&
                        formik.touched.dealerType ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.dealerType}
                          </Form.Label>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("eleinvoicecode")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="einvoicing"
                          value={formik.values.einvoicing}
                          onChange={handleInvoiceCode}
                          disabled={update ? true : false}
                        />
                        {formik.errors.einvoicing &&
                        formik.touched.einvoicing ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.einvoicing}
                          </Form.Label>
                        ) : null}
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("preferredpaymenttype")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        {!update && (
                          <Multiselect
                            classNamePrefix="Select2"
                            options={PREFERRED_PAYMENT}
                            singleSelect
                            placeholder="Preferred Payment"
                            displayValue="label"
                            name="preferredPaymentType"
                            menuIsOpen={openPreferredPayment}
                            onMenuOpen={handleOpenPreferredPayment}
                            onMenuClose={handleClosePreferredPayment}
                            value={PREFERRED_PAYMENT.find(
                              (option) =>
                                option.value ===
                                formik.values.preferredPaymentType
                            )}
                            onChange={(selectedOption) =>
                              formik.setFieldValue(
                                "preferredPaymentType",
                                selectedOption.value
                              )
                            }
                          />
                        )}
                        {update && (
                          <Form.Control
                            type="text"
                            name="preferredPaymentType"
                            value={formik.values.preferredPaymentType}
                            onChange={formik.handleChange}
                            disabled={update ? true : false}
                          />
                        )}
                        {formik.errors.preferredPaymentType &&
                        formik.touched.preferredPaymentType ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.preferredPaymentType}
                          </Form.Label>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      {/* <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">{t("walletamount")} :</Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="number"
                          name="walletAmount"
                          value={formik.values.walletAmount}
                          onChange={formik.handleChange}
                          disabled
                        />
                      </Col> */}
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("rechargelimit")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="number"
                          name="rechargeLimit"
                          value={formik.values.rechargeLimit}
                          onChange={formik.handleChange}
                          disabled
                        />
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("companyname")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="companyName"
                          value={formik.values.companyName}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="text-dark">
                          {t("uniqueid")} :
                        </Form.Label>
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          name="uniqId"
                          value={data.uniqId}
                          disabled
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </div>
            </Card.Body>
          </Card>
        </form>
      </div>
    </Fragment>
  );
};

export default withLoader(FreeDealerProfile);
