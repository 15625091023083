export const SUCCESS = "success";
export const ERROR = "error";
export const ANCHORORIGIN = {
  horizontal: "right",
  vertical: "bottom",
};
export const PAGELIMIT = 10;
export const EMPTY = "";

export const FORMIKERROR = {
  fontSize: "12px",
  color: "red",
  marginTop: "3px",
};

export const DASHBOARD_LABEL = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const CUSTOMDROPDOWNSTYLE = {
  option: (provided, state) => ({
    ...provided,
    ":hover": {
      color: "#000000de",
    },
  }),
};

export const CUSTOM_STYLES = {
  headCells: {
    style: {
      position: "sticky",
      right: 0,
      "&:last-child": {
        backgroundColor: "#ffffff", // Set the background color for the last column
      },
    },
  },
  cells: {
    style: {
      position: "sticky",
      right: 0,
    },
  },
  rows: {
    style: {
      ":hover": {
        backgroundColor: "#EEEEEE !important",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#fafafa", // Set the background color for even rows
      },
      "&:nth-child(even)": {
        backgroundColor: "#FFFFFF", // Set the background color for odd rows
      },
      "&:nth-child(even) > :last-child": {
        backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
      },
      "&:nth-child(odd) > :last-child": {
        backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
      },

      "&:nth-child(even):hover": {
        "& > :last-child": {
          backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on even rows
        },
      },
      "&:nth-child(odd):hover": {
        "& > :last-child": {
          backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on odd rows
        },
      },
    },
  },
};

export const ReplaceFunction = (number) => {
  // return number
  //   .toString()
  //   .replace(/,/g, "#")
  //   .replace(/\./g, ",")
  //   .replace(/#/g, ".");

  if (number === null || number === undefined) {
    return "0,00";
  } else if (/[a-zA-Z]/.test(number)) {
    return "0,00";
  }

  const numberDouble = parseFloat(number);
  let formattedNumber;

  if (numberDouble >= 10000000) {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "decimal",
      useGrouping: true,
    }).format(numberDouble);
  } else if (numberDouble >= 100000) {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "decimal",
      useGrouping: true,
    }).format(numberDouble);
  } else if (numberDouble >= 1000) {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "decimal",
      useGrouping: true,
    }).format(numberDouble);
  } else {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "decimal",
      useGrouping: false,
    }).format(numberDouble);
  }

  return formattedNumber
    .toString()
    .replace(/,/g, "#")
    .replace(/\./g, ",")
    .replace(/#/g, ".");
};

export const ReplaceFunctionWithoutDecimal = (number) => {
  if (number === null || number === undefined) {
    return "0";
  } else if (/[a-zA-Z]/.test(number)) {
    return "0";
  }

  const numberInt = Math.round(parseFloat(number));
  let formattedNumber;

  if (numberInt >= 10000000) {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "decimal",
      useGrouping: true,
    }).format(numberInt);
  } else if (numberInt >= 100000) {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "decimal",
      useGrouping: true,
    }).format(numberInt);
  } else if (numberInt >= 1000) {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "decimal",
      useGrouping: true,
    }).format(numberInt);
  } else {
    formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "decimal",
      useGrouping: false,
    }).format(numberInt);
  }

  return formattedNumber
    .toString()
    .replace(/,/g, "#")
    .replace(/\./g, ",")
    .replace(/#/g, ".");
};
