import React from "react";
import ExcelJS from "exceljs";
import moment from "moment";
import {
  ReplaceFunction,
  ReplaceFunctionWithoutDecimal,
} from "../../utils/constants";

const ReportByStreetExcel = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  // Process the data to match the desired format
  const processData = (rawData) => {
    return rawData.map((area) => ({
      area: area.area,
      data: area.data.map((item) => ({
        streetName: item.streetName || "Via sconosciuta",
        totalEvent: item.totalEvent,
        totalTime: Math.round(item.totalTime / 60000), // Convert milliseconds to minutes
        averageTime: Math.round(item.totalTime / 60000 / item.totalEvent),
        sumOfCharge: item.sumOfCharge,
      })),
    }));
  };

  const newData = [];
  for (let i = 0; i < data.length; i++) {
    newData.push(data[i].areaName);
  }
  const areaData = [...new Set(newData)];

  const finalOldData = [
    ...areaData.map((item) => ({
      area: item,
      data: data.filter((dt) => dt.areaName === item),
    })),
  ];

  const finalData = processData(finalOldData);

  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalAmount = 0;
  let totalEvent = 0;
  finalData.forEach((area) => {
    area.data.forEach((item) => {
      totalAmount += parseFloat(item.sumOfCharge);
      totalEvent += parseFloat(item.totalEvent);
    });
  });

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report per via");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      alignment: { horizontal: "center" },
      font: { color: { argb: "000000" }, bold: true },
    };

    // Add title
    const title = `Rendicontazione per: ${selectedCity.label}        Periodo: ${start} - ${end}`;
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { bold: true };
    worksheet.mergeCells("A1:E1");
    titleRow.alignment = { horizontal: "left" };

    // Add headers
    const headers = [
      "Via",
      "N. di soste",
      "Tempo totale di parcheggio in minuti",
      "Tempo medio di parcheggio in minuti",
      "Importo",
    ];

    let rowIndex = 3; // Start from row 3

    finalData.forEach((area) => {
      // Add area totals
      const areaTotalEvent = area.data.reduce(
        (sum, item) => sum + item.totalEvent,
        0
      );
      const areaTotalTime = area.data.reduce(
        (sum, item) => sum + item.totalTime,
        0
      );
      const areaTotalAverage = Math.floor(
        area.data.reduce(
          (sum, item) => sum + item.totalTime / item.totalEvent,
          0
        )
      );
      const areaTotalAmount = area.data.reduce(
        (sum, item) => sum + item.sumOfCharge,
        0
      );

      // Add area title
      const areaRow = worksheet.addRow([area.area]);
      areaRow.font = { bold: true };
      worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
      rowIndex++;

      // Add headers for this area
      const headerRow = worksheet.addRow(headers);
      headerRow.font = { bold: true };
      headerRow.eachCell((cell) => {
        cell.border = commonCellStyle.border;
      });
      headerRow.alignment = { horizontal: "center" };
      rowIndex++;

      // Add data for this area
      area.data.forEach((item) => {
        const dataRow = worksheet.addRow([
          item.streetName,
          ReplaceFunctionWithoutDecimal(item.totalEvent),
          ReplaceFunctionWithoutDecimal(item.totalTime),
          ReplaceFunctionWithoutDecimal(
            Math.floor(areaTotalTime / areaTotalEvent)
          ),
          // ReplaceFunctionWithoutDecimal(item.averageTime),
          "€ " + ReplaceFunction(item.sumOfCharge.toFixed(2)),
        ]);
        dataRow.eachCell((cell) => {
          cell.border = commonCellStyle.border;
        });
        dataRow.getCell(2).alignment = { horizontal: "center" };
        dataRow.getCell(3).alignment = { horizontal: "center" };
        dataRow.getCell(4).alignment = { horizontal: "center" };
        dataRow.getCell(5).alignment = { horizontal: "right" };
        dataRow.getCell(5).numFmt = "€#,##0.00";
        rowIndex++;
      });

      const totalRow = worksheet.addRow([
        "Totale",
        ReplaceFunctionWithoutDecimal(areaTotalEvent),
        ReplaceFunctionWithoutDecimal(areaTotalTime),
        ReplaceFunctionWithoutDecimal(Math.floor(areaTotalAverage)),
        "€ " + ReplaceFunction(areaTotalAmount.toFixed(2)),
      ]);
      totalRow.getCell(2).alignment = { horizontal: "center" };
      totalRow.getCell(3).alignment = { horizontal: "center" };
      totalRow.getCell(4).alignment = { horizontal: "center" };
      totalRow.getCell(5).alignment = { horizontal: "right" };
      totalRow.eachCell((cell) => {
        cell.border = commonCellStyle.border;
      });
      totalRow.font = { bold: true };
      totalRow.getCell(5).numFmt = "€#,##0.00";
      rowIndex++;

      // Add an empty row after each area
      worksheet.addRow([]);
      rowIndex++;
    });

    // Add footer
    const footerRow1 = worksheet.addRow([
      `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`,
    ]);
    footerRow1.font = { size: 10 };
    worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
    rowIndex++;

    // Set column widths
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 15;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Report_per_via_${selectedCity.label}_${moment(
      startDate,
      "DD/MM/YYYY"
    ).format("DDMMYYYYHHmm")}.xlsx`;
    link.click();
  };

  return downloadExcel;
};

export default ReportByStreetExcel;
