import React from "react";
import ExcelJS from "exceljs";
import moment from "moment";
import {
  ReplaceFunction,
  ReplaceFunctionWithoutDecimal,
} from "../../utils/constants";

const SummaryByCityExcel = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalEvent = 0;
  let totalTime = 0;
  let averageTime = 0;
  let totalNoVatSumOfCharge = 0;
  let totalVatSumofCharge = 0;
  let totalSumOfCharge = 0;
  let totalNoVatSumOfServiceFee = 0;
  let totalVatSumofServiceFee = 0;
  let totalSumOfServiceFee = 0;

  for (let i = 0; i < data?.length; i++) {
    totalEvent += parseFloat(data[i]?.totalEvent);
    totalTime += Number(data[i]?.totalTime / 60000);
    averageTime +=
      Number(data[i].totalTime / 60000) / Number(data[i]?.totalEvent);
    totalNoVatSumOfCharge += Number(data[i]?.sumOfCharge / 1.22);
    totalVatSumofCharge +=
      Number(data[i]?.sumOfCharge) - Number(data[i]?.sumOfCharge / 1.22);
    totalSumOfCharge += Number(data[i]?.sumOfCharge);
    // totalNoVatSumOfCharge += data[i]?.sumOfCharge;
    // totalVatSumofCharge += (data[i]?.sumOfCharge * 22) / 100;
    // totalSumOfCharge +=
    //   Number(data[i].sumOfCharge) +
    //   Number((data[i].sumOfServicefee * 22) / 100);

    totalNoVatSumOfServiceFee += Number(data[i]?.sumOfServicefee / 1.22);
    totalVatSumofServiceFee +=
      Number(data[i]?.sumOfServicefee) -
      Number(data[i]?.sumOfServicefee / 1.22);
    totalSumOfServiceFee += Number(data[i]?.sumOfServicefee);

    // totalNoVatSumOfServiceFee += data[i]?.sumOfServicefee;
    // totalVatSumofServiceFee += (data[i]?.sumOfServicefee * 22) / 100;
    // totalSumOfServiceFee +=
    //   Number(data[i].sumOfServicefee) +
    //   Number((data[i].sumOfServicefee * 22) / 100);
  }

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Riepilogo per città");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      alignment: { horizontal: "center" },
      font: { color: { argb: "000000" }, bold: true },
    };

    // Add title
    const title = `Rendicontazione per: ${selectedCity.label}        Periodo: ${start} - ${end}`;
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { size: 15, bold: true };
    titleRow.height = 30;
    worksheet.mergeCells("A1:K1");
    titleRow.alignment = { horizontal: "left", vertical: "middle" };

    worksheet.addRow([]);
    worksheet.mergeCells("A2:K2");

    // Add headers
    const headers = [
      "Data",
      "N. di soste",
      "Tempo totale di parcheggio in minuti",
      "Tempo medio di parcheggio in minuti",
      "Importo totale degli eventi di parcheggio senza IVA 22%",
      "Totale IVA 22% dell'importo totale del parcheggio",
      "Importo totale del parcheggio",
      "Importo totale del servizio senza IVA 22%",
      "Totale IVA 22% dell'importo totale del servizio",
      "Importo totale della commissione di servizio",
      "Importo totale del parcheggio",
    ];

    const headerRow = worksheet.addRow(headers);
    headerRow.font = { bold: true };
    headerRow.height = 60;
    headerRow.eachCell((cell) => {
      cell.border = commonCellStyle.border;
      cell.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
    });

    // Add data
    data.forEach((item) => {
      const row = worksheet.addRow([
        moment(item.date, "DD/MM/YYYY").format("DD/MM/YY"),
        ReplaceFunctionWithoutDecimal(item.totalEvent),
        ReplaceFunctionWithoutDecimal(Math.round(item.totalTime / 60000)),
        ReplaceFunctionWithoutDecimal(
          Math.round(item.totalTime / 60000 / item.totalEvent)
        ),
        ReplaceFunction((item.sumOfCharge / 1.22).toFixed(2)) + " €",
        ReplaceFunction(
          item.sumOfCharge.toFixed(2) - (item.sumOfCharge / 1.22).toFixed(2)
        ) + " €",
        ReplaceFunction(item.sumOfCharge.toFixed(2)) + " €",
        ReplaceFunction((item.sumOfServicefee / 1.22).toFixed(2)) + " €",
        ReplaceFunction(
          item.sumOfServicefee.toFixed(2) -
            (item.sumOfServicefee / 1.22).toFixed(2)
        ) + " €",
        ReplaceFunction(item.sumOfServicefee.toFixed(2)) + " €",
        ReplaceFunction(item.sumOfCharge.toFixed(2)) + " €",
      ]);

      row.eachCell((cell) => {
        cell.border = commonCellStyle.border;
        cell.alignment = { horizontal: "center" };
      });

      // Format currency cells
      [5, 6, 7, 8, 9, 10, 11].forEach((col) => {
        row.getCell(col).numFmt = "€#,##0.00";
      });
    });

    // Add totals row
    const totalsRow = worksheet.addRow([
      "Totale",
      ReplaceFunctionWithoutDecimal(totalEvent),
      ReplaceFunctionWithoutDecimal(Math.round(totalTime)),
      ReplaceFunctionWithoutDecimal(Math.round(totalTime / totalEvent)),
      // ReplaceFunctionWithoutDecimal(Math.round(averageTime)),
      ReplaceFunction(totalNoVatSumOfCharge.toFixed(2)) + " €",
      ReplaceFunction(totalVatSumofCharge.toFixed(2)) + " €",
      ReplaceFunction(totalSumOfCharge.toFixed(2)) + " €",
      ReplaceFunction(totalNoVatSumOfServiceFee.toFixed(2)) + " €",
      ReplaceFunction(totalVatSumofServiceFee.toFixed(2)) + " €",
      ReplaceFunction(totalSumOfServiceFee.toFixed(2)) + " €",
      ReplaceFunction(totalSumOfCharge.toFixed(2)) + " €",
    ]);

    totalsRow.eachCell((cell) => {
      cell.border = commonCellStyle.border;
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
    });

    // Format currency cells in totals row
    [5, 6, 7, 8, 9, 10, 11].forEach((col) => {
      totalsRow.getCell(col).numFmt = "€#,##0.00";
    });

    worksheet.addRow([]);

    // Add footer
    const footerRow = worksheet.addRow([
      `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`,
    ]);
    footerRow.font = { size: 12 };
    worksheet.mergeCells(`A${worksheet.rowCount}:K${worksheet.rowCount}`);

    // Set column widths
    worksheet.columns.forEach((column, index) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        maxLength = Math.max(
          maxLength,
          cell.value ? cell.value.toString().length : 0
        );
      });
      column.width = Math.min(maxLength + 2, 30);
    });

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Riepilogo_per_città_${selectedCity.label}_${moment(
      startDate,
      "DD/MM/YYYY"
    ).format("DDMMYYYYHHmm")}.xlsx`;
    link.click();
  };

  return downloadExcel;
};

export default SummaryByCityExcel;
