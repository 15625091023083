import jsPDF from "jspdf";
import { ReplaceFunction } from "../../utils/constants";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";

const ReportServiceFeesCollected = ({
  adminDetails,
  data,
  startDate,
  endDate,
  selectedArea,
  selectedCity,
}) => {
  const start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
  const end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");

  let totalServiceFee = 0;
  let totalEvent = 0;
  data.forEach((item) => {
    totalServiceFee += parseFloat(item.totalServiceFee);
    totalEvent += parseFloat(item.totalEvent);
  });

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape";
    const titleFontSize = 16;
    const textColor = [0, 0, 0];

    const doc = new jsPDF(orientation, unit, size);

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        const footer1 = `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town} Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax}`;
        doc.setFontSize(13);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(footer1, 40, doc.internal.pageSize.height - 20);

        const footer2 = `Page ${i} of ${pageCount}`;
        doc.setFontSize(13);
        doc.setDrawColor(...textColor);
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
        doc.text(
          footer2,
          doc.internal.pageSize.width - 100,
          doc.internal.pageSize.height - 20
        );
      }
    };

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 950, 40, 180, 80);

    const title = `Report service fee             Periodo: ${start} - ${end}`;
    doc.setFontSize(titleFontSize);
    doc.setDrawColor(...textColor);
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "normal");
    doc.text(title, 40, 100);

    const headers = [["Città", "N. di soste", "Importo"]];

    const areaData = data.map((elt) => [
      {
        content: elt.cityName,
        styles: { halign: "left" },
      },
      {
        content: elt.totalEvent,
        styles: { halign: "right" },
      },
      {
        content: ReplaceFunction(elt.totalServiceFee.toFixed(2)) + " €",
        styles: { halign: "right" },
      },
    ]);

    areaData.push([
      {
        content: "Totale",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 0, bottom: 1, left: 1 },
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: totalEvent,
        colSpan: 1,
        styles: {
          halign: "right",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 1, bottom: 1, left: 1 },
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
      {
        content: ReplaceFunction(totalServiceFee.toFixed(2)) + " €",
        colSpan: 1,
        styles: {
          halign: "right",
          fontStyle: "bold",
          lineWidth: { top: 1, right: 1, bottom: 1, left: 1 },
          lineColor: [...textColor],
          cellPadding: { top: 10, bottom: 7, left: 5, right: 5 },
        },
      },
    ]);

    const headStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      fontStyle: "bold",
      fontSize: 13,
      lineWidth: { top: 1, right: 1, bottom: 0, left: 1 },
      lineColor: [...textColor],
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 13,
      lineWidth: { top: 1, right: 1, bottom: 0, left: 1 },
      lineColor: [...textColor],
    };

    const alternateRowStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
    };

    const columnStyles = {
      0: { cellWidth: 300 },
      1: { cellWidth: 100 },
      2: { cellWidth: 200 },
      3: { cellWidth: 200 },
      4: { cellWidth: 100 },
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 140,
        head: headers,
        body: areaData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    addFooter(doc);

    doc.save(
      `Report_service_fee_incassate_${moment(startDate, "DD/MM/YYYY").format(
        "DDMMYYYYHHmm"
      )}.pdf`
    );
  };

  return exportPDF;
};

export default ReportServiceFeesCollected;
